import React, { Component } from "react";
import Http from "../../global/Actions/http"
import { Link } from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText, Icon, Link as LinkUI, Accordion, AccordionSummary, Typography, AccordionDetails, Hidden, Badge } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FolderIcon from '@material-ui/icons/Folder';


class Menu extends Component {

    constructor(props) {
        const userSession = JSON.parse(localStorage.getItem('userSession'));

        super(props);
        this.state = {
            expanded: window.innerWidth < 960 ? false : true,
            menuexpanded: {
                employee: true,
                responsible: userSession.idRole === 2 ? true : false,
                rrhh: userSession.idRole === 3 ? true : false,
                admin: userSession.idRole === 4 ? true : false
            },
            windowWidth: window.innerWidth,
            notifications: {
                approvals: 0
            },
            userSession: userSession
        }
        this.getTotalNotifications = this.getTotalNotifications.bind(this);
    }

    getTotalNotifications() {
        this.setState({ notifications: { approvals: 0 } });
        Http.Call("GET", "Requests/HistoryMyTeam").then(response => {
            if (!response.error) {
                var requests = response.data.filter(function (x) {
                    return x.idRequestStatus == 3
                });
                this.setState({ notifications: { approvals: requests.length } });
            }
        });
    }

    componentDidMount() {
        if (this.state.userSession.idRole > 1) {
            this.getTotalNotifications()
            this.NotifInterval = setInterval(
                () => this.getTotalNotifications(),
                120000
            );
        }

        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        clearInterval(this.NotifInterval);
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        let prevWindowWidth = this.state.windowWidth;
        this.setState({
            windowWidth: window.innerWidth
        });
        console.log("antes:" + prevWindowWidth + ", ahora: " + window.innerWidth);
        if (prevWindowWidth >= 960 && window.innerWidth < 960) {
            this.setState({ expanded: false });
        } else if(prevWindowWidth <= 960 && window.innerWidth > 960) {
            this.setState({ expanded: true });
        }
    }

    render() {
        const { userSession, expanded, notifications, menuexpanded } = this.state;
        const idTenant = localStorage.getItem('idTenant');

        return (
            <div className="menu">
                <Accordion square expanded={expanded} onChange={() => this.setState({expanded:expanded ? false : true})}>
                    <Hidden mdUp>
                        <AccordionSummary aria-controls="expandir-content" id="expandir-header" expandIcon={<ExpandMoreIcon></ExpandMoreIcon>}>
                            <Typography>Expandir/Ocultar Menú</Typography>
                        </AccordionSummary>
                    </Hidden>
                    <AccordionDetails>


                        <List className="menuOptions" component="nav"  >
                            { userSession &&
                                <Accordion className="menuAccordion" square expanded={menuexpanded.employee} onChange={() => this.setState(prevState => ({ menuexpanded: { ...prevState.menuexpanded, employee: menuexpanded.employee ? false : true } }))}>
                                <AccordionSummary aria-controls="expandir-content" id="expandir-header" expandIcon={<ExpandMoreIcon></ExpandMoreIcon>}>
                                        <h4>Menú empleado</h4>
                                </AccordionSummary>
                                { idTenant<3 && //TODO
                                <Link to="/MyCompany" onClick={this.accordionCollapse}>
                                    <ListItem button>
                                        <ListItemIcon>
                                            <Icon>apartment</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary="Mi empresa" />
                                    </ListItem>
                                    </Link>
                                }
                                <Link to="/AdminTime" onClick={this.accordionCollapse}>
                                    <ListItem button>
                                        <ListItemIcon>
                                            <Icon>watch_later</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary="Control horario" />
                                    </ListItem>
                                </Link>
                                <Link to="/WorkTime" onClick={this.accordionCollapse}>
                                    <ListItem button>
                                        <ListItemIcon>
                                            <Icon>access_alarm</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary="Parte de trabajo" />
                                    </ListItem>
                                </Link>
                                <Link to="/Requests/1" onClick={this.accordionCollapse}>
                                    <ListItem button>
                                        <ListItemIcon>
                                            <Icon>local_airport</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary="Ausencias" />
                                    </ListItem>
                                </Link>
                                <Link to="/Requests/2" onClick={this.accordionCollapse}>
                                    <ListItem button>
                                        <ListItemIcon>
                                            <Icon>question_answer</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary="Solicitudes" />
                                    </ListItem>
                                </Link>
                                <Link to="/Requests/3" onClick={this.accordionCollapse}>
                                    <ListItem button>
                                        <ListItemIcon>
                                            <Icon>card_travel</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary="Documentos" />
                                    </ListItem>
                                </Link>
                                <Link to="/Personal" onClick={this.accordionCollapse}>
                                    <ListItem button>
                                        <ListItemIcon>
                                            <Icon>groups</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary="Directorio" />
                                    </ListItem>
                                </Link>
                                <Link to="/Organization" onClick={this.accordionCollapse}>
                                    <ListItem button>
                                        <ListItemIcon>
                                            <Icon>account_tree</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary="Organigrama" />
                                    </ListItem>
                                </Link>
                                <Link to="/Help" onClick={this.accordionCollapse}>
                                    <ListItem button>
                                        <ListItemIcon>
                                            <Icon>help</Icon>
                                        </ListItemIcon>
                                        <ListItemText primary="Ayuda" />
                                    </ListItem>
                                </Link>
                                <Hidden smUp>
                                    <Link to="/">
                                        <ListItem button>
                                            <ListItemIcon>
                                                <Icon>logout</Icon>
                                            </ListItemIcon>
                                            <ListItemText primary="Desconectar" />
                                        </ListItem>
                                    </Link>
                                </Hidden>
                            </Accordion>
                            }
                            {userSession.idRole === 2 || userSession.idRole === 4 ?
                                <Accordion className="menuAccordion" square expanded={menuexpanded.responsible} onChange={() => this.setState(prevState => ({ menuexpanded: { ...prevState.menuexpanded, responsible: menuexpanded.responsible ? false : true } }))}>
                                    <AccordionSummary aria-controls="expandir-content" id="expandir-header" expandIcon={<ExpandMoreIcon></ExpandMoreIcon>}>
                                        <h4>Menú responsable</h4>
                                    </AccordionSummary>
                                    
                                    <Link to="/ListUsers/MyTeam" onClick={this.accordionCollapse}>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <Icon>manage_accounts</Icon>
                                            </ListItemIcon>
                                            <ListItemText primary="Mi equipo" />
                                        </ListItem>
                                    </Link>
                                    <Link to="/History/MyTeam" onClick={this.accordionCollapse}>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <Icon>history</Icon>
                                            </ListItemIcon>
                                            <ListItemText primary="Historial" />
                                        </ListItem>
                                    </Link>
                                    <Link to="/Approvals" onClick={this.accordionCollapse}>
                                        <ListItem button>
                                            <Badge className="totalapprovals" badgeContent={notifications.approvals}>
                                                <FolderIcon color="action" />
                                            </Badge>

                                            <ListItemText primary="Aprobaciones" style={{"marginLeft":"33px"}} />
                                        </ListItem>
                                    </Link>
                                    <Link to="/Absencesteam" onClick={this.accordionCollapse}>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <Icon>event</Icon>
                                            </ListItemIcon>
                                            <ListItemText primary="Calendario" />
                                        </ListItem>
                                    </Link>
                                    <Link to="/Projects" onClick={this.accordionCollapse}>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <Icon>pages</Icon>
                                            </ListItemIcon>
                                            <ListItemText primary="Proyectos" />
                                        </ListItem>
                                    </Link>
                                   </Accordion>
                                :
                                null}
                            
                            {userSession.idRole === 3 || userSession.idRole === 4 ?
                                <Accordion className="menuAccordion" square expanded={menuexpanded.rrhh} onChange={() => this.setState(prevState => ({ menuexpanded: { ...prevState.menuexpanded, rrhh: menuexpanded.rrhh ? false : true } }))}>
                                        <AccordionSummary aria-controls="expandir-content" id="expandir-header" expandIcon={<ExpandMoreIcon></ExpandMoreIcon>}>
                                            <h4>Menú RRHH</h4>
                                        </AccordionSummary>
                                    <Link to="/ListUsers/All" onClick={this.accordionCollapse}>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <Icon>manage_accounts</Icon>
                                            </ListItemIcon>
                                            <ListItemText primary="Empleados" />
                                        </ListItem>
                                    </Link>
                                    <Link to="/History/All" onClick={this.accordionCollapse}>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <Icon>history</Icon>
                                            </ListItemIcon>
                                            <ListItemText primary="Historial" />
                                        </ListItem>
                                    </Link>
                                    <Link to="/WorkCalendar" onClick={this.accordionCollapse}>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <Icon>calendar_month</Icon>
                                            </ListItemIcon>
                                            <ListItemText primary="Calendarios" />
                                        </ListItem>
                                    </Link>
                                    <Link to="/Alerts" onClick={this.accordionCollapse}>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <Icon>notifications</Icon>
                                            </ListItemIcon>
                                            <ListItemText primary="Alertas" />
                                        </ListItem>
                                    </Link>
                                    <Link to="/Projects" onClick={this.accordionCollapse}>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <Icon>pages</Icon>
                                            </ListItemIcon>
                                            <ListItemText primary="Proyectos" />
                                        </ListItem>
                                    </Link>
                                </Accordion>
                                : null}
                                {userSession.idRole === 4 ?
                                <Accordion className="menuAccordion" square expanded={menuexpanded.admin} onChange={() => this.setState(prevState => ({ menuexpanded: { ...prevState.menuexpanded, admin: menuexpanded.admin ? false : true } }))}>
                                    <AccordionSummary aria-controls="expandir-content" id="expandir-header" expandIcon={<ExpandMoreIcon></ExpandMoreIcon>}>
                                        <h4>Menú administrador</h4>
                                    </AccordionSummary>
                                    <Link to="/Company" onClick={this.accordionCollapse}>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <Icon>business</Icon>
                                            </ListItemIcon>
                                            <ListItemText primary="Empresa" />
                                        </ListItem>
                                    </Link>
                                    <Link to="/Workplaces" onClick={this.accordionCollapse}>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <Icon>badge</Icon>
                                            </ListItemIcon>
                                            <ListItemText primary="Centros de trabajo" />
                                        </ListItem>
                                    </Link>
                                    <Link to="/Departments" onClick={this.accordionCollapse}>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <Icon>groups</Icon>
                                            </ListItemIcon>
                                            <ListItemText primary="Departamentos" />
                                        </ListItem>
                                    </Link>
                                    <Link to="/Projects" onClick={this.accordionCollapse}>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <Icon>pages</Icon>
                                            </ListItemIcon>
                                            <ListItemText primary="Proyectos" />
                                        </ListItem>
                                    </Link>
                                    <Link to="/Reports" onClick={this.accordionCollapse}>
                                        <ListItem button>
                                            <ListItemIcon>
                                                <Icon>bar_chart</Icon>
                                            </ListItemIcon>
                                            <ListItemText primary="Informes" />
                                        </ListItem>
                                    </Link>
                                </Accordion>:null}
                                

                        </List>
                    </AccordionDetails>
                </Accordion>
            </div>
        );
    }
}

export default Menu;
