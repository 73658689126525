import React, { Component } from "react";
import { TextField, Button, NativeSelect, Grid } from '@material-ui/core';
import Http from "../../../global/Actions/http"
import Loading from '../../../global/Components/loading';
import Moment from 'react-moment';
import DynamicTable from "../../../global/Components/dynamicTable";

class viewReport extends Component {

    constructor(props) {
        const userSession = JSON.parse(localStorage.getItem('userSession'));
        super(props);
        this.state = {
            idReport: this.props.idReport,
            loading: true,
            report: {
                reportTitle:''
            },
            idUser: userSession.idUser,
        }
        this.getReport = this.getReport.bind(this);
    }

    componentDidMount() { // Eventos que se lanzarán al cargar el componente
        this.getReport();
    }

    getReport() {
        if (this.state.idReport > 0) {
            Http.Call("GET", "Reports/Details/" + this.state.idReport).then(response => { // Captura de ausencias guardadas del usuario en curso
                if (!response.error) {
                    this.setState({ report: response.data, loading: false }); // Las trasladamos al state
                }
            });
        } else {
            this.setState({ Report: { ReportName: '' }, loading: false })
        }
    }

    render() {
        const { report, loading } = this.state; // Creo constantes de objetos a partir del state del componente
        return (
                <div>
                { loading ? <Loading /> :
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <iframe title={report.reportTitle} id="reportemb" width="100%" height="600" src={report.reportEmbedCode} frameborder="0" allowFullScreen="true"></iframe>
                            </Grid>
                        </Grid> </>
                    }
                </div>
        );
    }
}

export default viewReport;
