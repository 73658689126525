import React, { Component } from "react";
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import { Button, Dialog, Card, IconButton, Icon } from '@material-ui/core';
import Http from "../../../global/Actions/http"
import CardHeader from "../../../global/Components/cardHeader"

class Vacations extends Component {

    constructor(props) {
        const userSession = JSON.parse(localStorage.getItem('userSession'));
        super(props);
        this.state = {
            idUser: this.props.idUser ? this.props.idUser : userSession.idUser,
            year: this.props.year,
            VacationsAvailable: 0,
            Vacations: {
                approved: 0,
                free: 0,
                requested: 0
            },
            VacationsAdjustment: [],
            dialog: {
                open: false,
            },
            loading: true
        }
    }

    componentDidMount() { // Eventos que se lanzarán al cargar el componente
        Http.Call("GET", "Requests/Vacations/"+this.state.idUser+"/"+this.state.year).then(response => { // Captura de ausencias guardadas del usuario en curso
            if (!response.error) {

                var available = response.data.vacationsAvailable;

                var approved = 0;
                var dapproved = response.data.vacationsHistory.filter(function (x) { return x.idRequestStatus == 1 });
                dapproved.map(item => { approved = approved + (item.vacationDays - item.nonWorkingDays); });

                var requested = 0;
                var drequested = response.data.vacationsHistory.filter(function (x) { return x.idRequestStatus == 3 });
                drequested.map(item => { requested = requested + (item.vacationDays - item.nonWorkingDays); });

                response.data.vacationsAdjustment.map(item => {
                        available = available + item.userYearAdjust
                });

                var free = available - requested - approved;
                this.setState({ VacationsAvailable: available, Vacations: { approved: approved, free: free, requested: requested }, VacationsAdjustment: response.data.vacationsAdjustment }); // Las trasladamos al state
            }
        });
    }

    render() {
        const legendMargin = {
            id: 'legendMaring',
            beforeInit(chart, legend, options) {
                const fitValue = chart.legend.fit;
                chart.legend.fit = function fit() {
                    fitValue.bind(chart.legend)();
                    return this.height += 20;
                }
            }
        };
        const { Vacations, VacationsAdjustment, dialog, year } = this.state;
        const data = {
            labels: ['Aprobadas (' + Vacations.approved + ')', 'Solicitadas (' + Vacations.requested + ')', 'Disponibles (' + Vacations.free +')'],
            datasets: [
                {
                    label: 'Vacaciones',
                    data: [Vacations.approved, Vacations.requested, Vacations.free],
                    backgroundColor: [
                        '#33a033',
                        'lightgoldenrodyellow',
                        '#f1f1f1',
                    ],
                    borderColor: [
                        '#33a033',
                        'lightgoldenrodyellow',
                        '#f0f0f0',
                    ],
                    borderWidth: 1,
                },
            ],
        };

        return (
            <div className={VacationsAdjustment.length > 0 ? "doughnut" : "doughnut pt10"}>
                <Http/>
                <Doughnut data={data} height={290} options={{ maintainAspectRatio: false }} plugins={[legendMargin]} />
                { VacationsAdjustment.length>0 &&
                <div className="details" onClick={() => { this.setState({ dialog: { open: true } }) }}>Se han realizado ajustes, click para ver detalles.</div>
                }
                <Dialog
                    open={dialog.open}
                    fullWidth
                    maxWidth="sm"
                    onClose={() => { this.setState({ dialog: { open: false }})}}
                >
                    <Card style={{ width: "100%" }}>
                        <CardHeader title={"Detalle de ajustes para el año " +year} icon="feed" color="brown" />
                        <IconButton className="modalClose" component="span" onClick={() => { this.setState({ dialog: { open: false } }) }}>
                            <Icon>close</Icon>
                        </IconButton>
                        <div className="people-card-body" style={{ maxHeight: "500", overflowY: "auto" }}>
                            {
                                VacationsAdjustment.map(item =>
                                    <div key={"adj-" + item.idUserYear}>
                                        <b>Tipo de ajuste:</b> {item.userYearAdjust > 0 ? "+" + item.userYearAdjust : item.userYearAdjust } días.<br />
                                        <b>Motivo del ajuste:</b> {item.userYearRemarks}
                                        <hr/>
                                    </div>
                                )
                            }
                        </div>
                    </Card>

                </Dialog>

                </div>
        );
    }
}

export default Vacations;
