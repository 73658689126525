import React, { useState, useEffect } from "react";
import DynamicTable from "../../global/Components/dynamicTable";
import { ButtonGroup, Container, Button, Grid, Card, Icon, Tooltip, Chip } from '@material-ui/core';
import CardHeader from '../../global/Components/cardHeader';
import Modal from '../../global/Components/modal';
import Moment from 'react-moment';
import { useParams } from "react-router-dom";
import DetailRequest from "../Requests/components/detailRequest";

import { Link } from "react-router-dom";

function Approvals() {

    const userSession = JSON.parse(localStorage.getItem('userSession'));
    const endpoint = useParams().filter;
    const [reload, setReload] = useState(0);
    const [key, setKey] = useState(Math.random());
    const [idRequestTypeParent, setIdRequestTypeParent] = useState(1);
    const [modal, setModal] = useState({
        open: false,
        title: "",
        icon: "",
        color: "",
        component: "",
        size: "",
        key: Math.random()
    });

    //Definición de columnas
    const columns = [
        { accessor: "idRequest", Header: "Nº", width: "7%" },
        { accessor: "requestCreated", width: "15%", Header: "Fecha solicitud", Cell: props => <Moment format="DD/MM/YYYY HH:mm" utc local>{props.value}</Moment> },
        { accessor: "userFullname", width: "25%", Header: "Empleado" },
        { accessor: "userOffice", width: "15%", Header: "Ubicación" },
        { accessor: "requestTypeParentName", width: "10%", Header: "Categoría" },
        { accessor: "requestTypeName", width: "10%", Header: "Detalle" },
        { accessor: "idRequestStatus", width: "10%", Header: "Estado", Cell: props => <div><Chip label={props.value == 1 ? "APROBADA" : props.value == 2 ? "DENEGADA" : "PENDIENTE"} className={props.value == 1 ? "gLight" : props.value == 2 ? "rLight" : "yLight"} /></div> },
        {
            Header: "Acciones", filterable: false, width: "5%", Cell: props =>
                <div>
                    <Tooltip title="Ver detalles">
                            <Button variant="outlined" onClick={() => { setModal({ open: true, height: "80vh", title: "Ver detalle de solicitud", icon: "manage_accounts", color: "blue", size: "sm", component: <DetailRequest setReload={() => setReload(Math.random())} idRequest={props.row.original.idRequest} />, key: Math.random() }) }}><Icon>more_horiz</Icon></Button>
                    </Tooltip>
                </div>
        }
    ];
    const [customFilter, setCustomFilter] = useState([
        {
            SearchField: "idRequestStatus",
            SearchValue: "0",
            SearchMode: 'EqualTo'
        },
        {
            SearchField: "idRequestTypeParent",
            SearchValue: "" + idRequestTypeParent + "",
            SearchMode: 'EqualTo'
        }
    ]);
    const customOrder = [
        {
            orderField: "requestCreated",
            orderAsc: true,
        }
    ];
    
    useEffect(() => {
        setCustomFilter([
                {
                    SearchField: "idRequestStatus",
                    SearchValue: "3",
                    SearchMode: 'EqualTo'
                },
                {
                    SearchField: "idRequestTypeParent",
                    SearchValue: "" + idRequestTypeParent + "",
                    SearchMode: 'EqualTo'
                }
            ]);
        setKey(Math.random());
    }, [idRequestTypeParent]);

    return (
        <Container className="approvals" maxWidth="xl">
            {/* ---------------- CABECERA -------------------- */}
            <Grid id="header" container spacing={5} padding={10}>
                <Grid item xs={12}><h2>Aprobaciones</h2></Grid>
            </Grid>
            {/* ---------------- FIN CABECERA -------------------- */}
            <ButtonGroup className="grouplist" size="small" >
                <Button onClick={() => { setIdRequestTypeParent(1) }} className={idRequestTypeParent == 1 ? "groupActive" : ""}>Ausencias</Button>
                <Button onClick={() => { setIdRequestTypeParent(2) }} className={idRequestTypeParent == 2 ? "groupActive" : ""}>Solicitudes</Button>
                <Button onClick={() => { setIdRequestTypeParent(3) }} className={idRequestTypeParent == 3 ? "groupActive" : ""}>Documentos</Button>
            </ButtonGroup>
            <Button component={Link} to="/History/MyTeam" className="showHistoryBtn">Ver Historial</Button>
            <Card className="people-card">
                <CardHeader title="Solicitudes pendientes de aprobación" icon="history" color="red" />

                <DynamicTable key={key} customFilter={customFilter} customOrder={customOrder} reload={reload} isGlobalSearch={true} isOrdered={true} columns={columns} endpoint={"Requests/HistoryMyTeam"} />
                <Modal key={modal.key} height={modal.height} open={modal.open} title={modal.title} icon={modal.icon} color={modal.color} component={modal.component} size={modal.size} />
            </Card>
        </Container>
    )
}

export default Approvals;