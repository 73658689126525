import React, { Component } from "react";
import Http from "../../global/Actions/http"
import { Container, Grid, TextField, NativeSelect, Button, Checkbox, Card } from '@material-ui/core';
import { Calendar } from "react-multi-date-picker";
import CardHeader from '../../global/Components/cardHeader';

class WorkCalendar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            idCalendar: 0,
            calendars: [],
            calendar: {
                calendarVacationDays: 0,
                calendarUntilDay:31,
                calendarUntilMonth:1
            },
            calendarDate: [],
            newcalendar: false,
            newname: "",
            year: new Date().getFullYear(),
            delText: "Eliminar",
            week: {
                L: false,
                M: false,
                X: false,
                J: false,
                V: false,
                S: false,
                D: false
            }
        }
        this.handleCalendar = this.handleCalendar.bind(this);
        this.handleMyCalendar = this.handleMyCalendar.bind(this);
        this.getCalendar = this.getCalendar.bind(this);
        this.getCalendars = this.getCalendars.bind(this);
        this.handleDates = this.handleDates.bind(this);
        this.createCalendar = this.createCalendar.bind(this);
        this.deleteCalendar = this.deleteCalendar.bind(this);
        this.fillDays = this.fillDays.bind(this);
        this.handleYear = this.handleYear.bind(this);
    }

    componentDidMount() {
        window.addEventListener('click', (e) => {
            if (e.target.className.includes("rmdp-arrow")) {
                this.handleYear();
            }
        });
        this.getCalendars();
    }


    getCalendars() {
        Http.Call("GET", "Calendars/All").then(response => {
            if (!response.error) {
                this.setState({ calendars: response.data, idCalendar: 0, calendarDate: [], newcalendar: false });
            }
        });
    }

    getCalendar() {
        Http.Call("GET", "Calendars/Details/" + this.state.idCalendar).then(response => {
            if (!response.error) {
                this.setState({ calendar: response.data });
            }
        });
        Http.Call("GET", "Calendars/getDates/"+this.state.idCalendar).then(response => {
            if (!response.error) {
                if (response.data.length > 0) {
                    var datesarray = [];
                    response.data.map(date => {
                        datesarray.push(date.calendarDate.substring(0,10));
                    });
                    this.setState({ calendarDate: datesarray });
                    this.handleYear();
                }
            }
        });
    }

    fillDays(e) {
        e.persist();
        this.setState(prevState => ({ week: { ...prevState.week, [e.target.name]: e.target.checked }}));
        var year = this.state.year;
        //console.log(year);
        var dayOfWeek = e.target.value;
        var checked = e.target.checked;
        var datesarray = this.state.calendarDate;
        for (var d = new Date(year, 0, 1); d <= new Date(year, 11, 31); d.setDate(d.getDate() + 1)) {
            if (d.getDay() == dayOfWeek) {
                var thisdate = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2);
                if (checked)
                {
                    if (!datesarray.includes(thisdate)) {
                        datesarray.push(thisdate);
                    }
                }
                else
                {
                    if (datesarray.includes(thisdate)) {
                        datesarray.splice(datesarray.indexOf(thisdate), 1);
                    }
                }
            }
        }
        this.setState({ calendarDate: datesarray });
        this.postCalendar();
    }

    handleYear() {
        this.setState({ year: document.querySelector(".rmdp-header-values").innerHTML });
        var datesarray = this.state.calendarDate;
        var statedays = ["D", "L", "M", "X", "J", "V", "S", "D"];
        for (var x = 0; x <= 6; x++) { //Comprobamos cada dia la semana
            var check = true;
            for (var d = new Date(this.state.year, 0, 1); d <= new Date(this.state.year, 11, 31); d.setDate(d.getDate() + 1)) {
                if (d.getDay() == x) {
                    var thisdate = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2);
                    if (!datesarray.includes(thisdate)) {
                        check = false;
                        break;
                    }
                }
            }
            this.setState(prevState => ({ week: { ...prevState.week, [statedays[x]]: check } }));
        }   
    }

    createCalendar() {
        Http.Call("POST", "Calendars/New", { CalendarName: this.state.newname }).then(response => {
            if (!response.error) {
                this.getCalendars();
            }
        });
    }

    deleteCalendar() {
        if (this.state.delText == "Eliminar") {
            this.setState({ delText: "¿Seguro?" });
        } else {
            Http.Call("DELETE", "Calendars/Delete/" + this.state.idCalendar).then(response => {
                if (!response.error) {
                    this.getCalendars();
                }
            });
            this.setState({ delText: "Eliminar" });
        }
    }

    async handleMyCalendar(e) { // Handle para controlar en state los valores de los elementos de nueva solicitud
        e.persist(); // Mantengo persistente el estado del elemento hasta el final del controlador
        var value = e.target.value; // Vuelco en la variable value el valor de elemento
        await this.setState(prevState => ({ calendar: { ...prevState.calendar, [e.target.name]: value } })); // Establezco en el state el valor del campo actual respetando el resto de elementos
        Http.Call("PUT", "Calendars/Update/" + this.state.idCalendar, this.state.calendar).then(response => {
            if (!response.error) {
                this.getCalendar();
            }
        });
    }

    async handleCalendar(e) { // Handle para controlar en state los valores de los elementos de nueva solicitud
        e.persist(); // Mantengo persistente el estado del elemento hasta el final del controlador
        var value = e.target.value; // Vuelco en la variable value el valor de elemento
        await this.setState({ [e.target.name]: value }); // Establezco en el state el valor del campo actual respetando el resto de elementos
        if (e.target.name == "idCalendar") {
            this.getCalendar();
        }
    }

    async handleDates(e) { // Handle para controlar en state los valores de los elementos de nueva solicitud
        var datesarray = [];
        e.map(date => {
            datesarray.push(date.year + "-" + date.month.number + "-" + date.day);
        });
        await this.setState({ calendarDate: datesarray });
        this.postCalendar();
    }

    postCalendar() {
        Http.Call("POST", "Calendars/setDates/" + this.state.idCalendar, { calendarDate: this.state.calendarDate }).then(response => {
            if (!response.error) {
                this.getCalendar();
            }
        });
    }

    render() {
        const es = {
            name: "es",
            months: [
                ["Enero", "Ene"],
                ["Febrero", "Feb"],
                ["Marzo", "Mar"],
                ["Abril", "Abr"],
                ["Mayo", "May"],
                ["Junio", "Jun"],
                ["Julio", "Jul"],
                ["Agosto", "Ago"],
                ["Septiembre", "Sep"],
                ["Octubre", "Oct"],
                ["Noviembre", "Nov"],
                ["Diciembre", "Dic"],
            ],
            weekDays: [
                ["Sábado", "Sa"],
                ["Domingo", "Do"],
                ["Lunes", "Lu"],
                ["Martes", "Ma"],
                ["Miércoles", "Mi"],
                ["Jueves", "Ju"],
                ["Viernes", "Vi"],
            ],
            digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
            meridiems: [
                ["AM", "AM"],
                ["PM", "PM"],
            ],
        };
        const { week, newname, newcalendar, calendars, idCalendar, calendarDate, delText, calendar } = this.state;
        return (
            <Container className="calendar" maxWidth="xl">
                <Grid id="header" className="section-header" container spacing={5} padding={10}>
                    <Grid item xs={12}><h2>Configuración de calendarios</h2></Grid>
                </Grid>
                <Http />
                <Grid item xs={12} id="up">
                    <Card className="people-card">
                        <CardHeader title="Calendarios laborales" icon="calendar_month" color="blue" />
                        <div className="people-card-body">
                            { newcalendar==true ? 
                            <Grid container spacing={3} id="up">
                                <Grid item xs={12} sm={6} lg={4}>
                                    <span>Nombre del calendario:</span><br />
                                    <TextField id="newname" name="newname" value={newname} onChange={this.handleCalendar} fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={6} lg={8}>
                                    <br />
                                        <Button className="calendarbtn" variant="outlined" color="primary" disabled={newname.length > 0 ? false : true} onClick={this.createCalendar}>Dar de alta</Button><span> </span>
                                    <Button className="calendarbtn" variant="outlined" onClick={() => this.setState({ newcalendar: false })}>Cancelar</Button>
                                </Grid>
                            </Grid>
                                :
                            <Grid container spacing={3} id="up">
                                <Grid item xs={12} sm={6} lg={2}>                                    
                                    <span>Selecciona calendario:</span><br />
                                    <NativeSelect id="idCalendar" name="idCalendar" fullWidth value={idCalendar} onChange={this.handleCalendar}>
                                        <option value="0" disabled>Selecciona calendario</option>
                                        {calendars.map(cal => <option key={"cal-" + cal.idCalendar} value={cal.idCalendar}>{cal.calendarName}</option>)}
                                    </NativeSelect>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={1}>
                                    <span>Nº vacaciones</span><br />
                                        <TextField type="number" max="31" disabled={idCalendar > 0 ? false : true} id="calendarVacationDays" name="calendarVacationDays" value={calendar.calendarVacationDays} onChange={this.handleMyCalendar} fullWidth />
                                    </Grid>
                                <Grid item xs={12} sm={6} lg={2}>
                                        <span>Límite disfrute vacaciones</span><br />
                                        <TextField disabled={idCalendar > 0 ? false : true} type="number" max="31" id="calendarUntilDay" name="calendarUntilDay" style={{ width:'70px', marginRight:'10px' }} value={calendar.calendarUntilDay} onChange={this.handleMyCalendar} />
                                        <NativeSelect disabled={idCalendar > 0 ? false : true} id="calendarUntilMonth" name="calendarUntilMonth" value={calendar.calendarUntilMonth} onChange={this.handleMyCalendar}>
                                            <option value="1">Enero</option>
                                            <option value="2">Febrero</option>
                                            <option value="3">Marzo</option>
                                            <option value="4">Abril</option>
                                            <option value="5">Mayo</option>
                                            <option value="6">Junio</option>
                                            <option value="7">Julio</option>
                                            <option value="8">Agosto</option>
                                            <option value="9">Septiembre</option>
                                            <option value="10">Octubre</option>
                                            <option value="11">Noviembre</option>
                                            <option value="12">Diciembre</option>
                                        </NativeSelect>
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={4}><br/>
                                        <Checkbox value="1" name="L" disabled={idCalendar > 0 ? false : true} checked={week.L} onClick={this.fillDays} />L
                                        <Checkbox value="2" name="M" disabled={idCalendar > 0 ? false : true} checked={week.M} onClick={this.fillDays} />M
                                        <Checkbox value="3" name="X" disabled={idCalendar > 0 ? false : true} checked={week.X} onClick={this.fillDays} />X
                                        <Checkbox value="4" name="J" disabled={idCalendar > 0 ? false : true} checked={week.J} onClick={this.fillDays} />J
                                        <Checkbox value="5" name="V" disabled={idCalendar > 0 ? false : true} checked={week.V} onClick={this.fillDays} />V
                                        <Checkbox value="6" name="S" disabled={idCalendar > 0 ? false : true} checked={week.S} onClick={this.fillDays} />S
                                        <Checkbox value="0" name="D" disabled={idCalendar > 0 ? false : true} checked={week.D} onClick={this.fillDays} />D
                                       
                                </Grid>
                                    <Grid item xs={12} sm={6} lg={3}>
                                        <br/>
                                        <Button className="calendarbtn" variant="outlined" color="primary" onClick={() => this.setState({ newcalendar: true })}>Nuevo calendario</Button>
                                        <Button className="calendarbtn" variant="outlined" className="float-right" disabled={idCalendar > 0 ? false : true} onClick={this.deleteCalendar}> {delText}</Button>
                                    </Grid>
                            </Grid>
                            }
                            <Grid container spacing={5} padding={10}>
                                <Grid item xs={12}>
                                    <Calendar
                                        disabled={ idCalendar>0 ? false : true }
                                        locale={es}
                                        weekStartDayIndex={1}
                                        className="workCalendar"
                                        multiple
                                        fullYear
                                        shadow={false}
                                        onChange={this.handleDates}
                                        value={calendarDate} />
                                </Grid>
                            </Grid>
                        </div>
                    </Card>
                </Grid>

                
            </Container>
        );
    }
}

export default WorkCalendar;
