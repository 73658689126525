import React, { Component } from "react";
import Http from "../../../global/Actions/http"
import Modal from '../../../global/Components/modal';
import { NativeSelect, Grid, Button } from '@material-ui/core';
import Moment from 'react-moment';
import Loading from '../../../global/Components/loading';
import TimeUpdate from "./TimeUpdate"

class TimeHistory extends Component {

    constructor(props) {
        super(props);
        const userSession = JSON.parse(localStorage.getItem('userSession'));
        this.state = {
            userSession: userSession, 
            idUser: this.props.idUser ? this.props.idUser : userSession.idUser,
            date: {
                month: (new Date().getMonth() + 1),
                year: new Date().getFullYear(),
                loading: true
            },
            years: [new Date().getFullYear() - 2, new Date().getFullYear() - 1, new Date().getFullYear()],
            months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciciembre'],
            history: [],
            modal: {
                open: false,
                title: "",
                icon: "",
                color: "",
                component: "",
                size: "",
                key: Math.random()
            },
        }
        this.handleMonthChange = this.handleMonthChange.bind(this);
        this.getMonth = this.getMonth.bind(this);
        this.updateThis = this.updateThis.bind(this);
    }

    componentDidMount() {
        this.getMonth();
    }

    updateThis() {
        //this.setState({ modal: { open: false } });
        this.getMonth();
    }

    getMonth() {
        Http.Call("POST", "Clock/Month", { idUser: this.state.idUser, Year: this.state.date.year, Month: this.state.date.month }).then(response => {
            if (!response.error) {
                this.setState({ history: response.data });
                this.setState(prevState => ({ date: { ...prevState.date, loading: false } }));
            }
        })
    }

    getTotalDay(checkday) { //Calculamos el total trabajo en un día
        var sum = 0; //Variable de milisegundos a sumar (basado en tiempo trabajo)
        var deduct = 0; //Variable de milisegundos a restar (basado en pausas)
        var start = null; //Hora de inicio de la jornada
        var pausestart = null; //Hora de inicio de la pausa

        var day = this.state.history.filter(function (day) {
            return day.clockDateTime.includes(checkday);
        });

        var lastEvent = 0;
        day.map(data => { //Lógica de calculo de totales
            if (data.idClockType == 1) { lastEvent = 1; start = new Date(data.clockDateTime); } // entrada 
            if (data.idClockType == 2) { lastEvent = 2; sum = sum + (new Date(data.clockDateTime) - start); } // salida
            if (data.idClockType == 3) { lastEvent = 3; pausestart = new Date(data.clockDateTime); } // pausa 
            if (data.idClockType == 4) { lastEvent = 4; deduct = deduct + (new Date(data.clockDateTime) - pausestart) } // regreso
        })

        var total = new Date("1900-01-01T00:00:00");
        if (lastEvent == 2) {
            total = total.setTime(total.getTime() + sum - deduct);
        }
        return total;

    }

    async handleMonthChange(e) {
        e.persist(); // Mantengo persistente el estado del elemento hasta el final del controlador
        var value = e.target.value; // Vuelco en la variable value el valor de elemento
        await this.setState(prevState => ({ date: { ...prevState.date, [e.target.name]: value, loading: true } })); // Establezco en el state el valor del campo actual respetando el resto de elementos
        this.getMonth();
    }

    render() {
        const { modal, date, years, months, history, idUser, userSession } = this.state;
        return (
            <div className="timehistory">
            <Http/>
            <Grid container spacing={5}>
                                    <Grid item xs={6} sm={3}>
                                        <NativeSelect name="month" value={date.month} fullWidth onChange={this.handleMonthChange} >
                                            {months.map((month, i) =>
                                                <option key={"month-" + month} value={i+1}>{month}</option>
                                            )}
                                        </NativeSelect>
                                    </Grid>
                                    <Grid item xs={6} sm={2}>
                                        <NativeSelect name="year" value={date.year} fullWidth onChange={this.handleMonthChange}>
                                            {years.map(year =>
                                                <option key={"year-"+year} value={year}>{year}</option>
                                            )}
                                        </NativeSelect>
                                    </Grid>
                    <Grid item xs={12} sm={7}>
                        {userSession.idRole !== 1 &&
                            <Button variant="outlined" className="float-right" color="primary" style={{ marginTop: "5px" }} onClick={() => this.setState({ modal: { open: true, title: "Añadir nuevo registro", icon: "manage_accounts", color: "blue", size: "sm", component: <TimeUpdate handler={this.updateThis} idUser={idUser} />, key: Math.random() } })}>Añadir registro</Button>
                                    }
                                    </Grid>
                                </Grid>
                                <div>
                                    {date.loading ? <Loading/> :
                                    <Grid container>
                                    {
                                            history.map((item, i) =>
                                                <React.Fragment key={"hist-"+i }>
                                                    {item.clockDateTime.substring(0, 10) != history[i == 0 ? 0 : i - 1].clockDateTime.substring(0, 10) || i == 0 ? <Grid item xs={12}><div className="people-list"><strong>Día <Moment format="DD">{item.clockDateTime}</Moment> (<Moment format="HH[ horas, ]mm[ minutos]" utc local>{this.getTotalDay(item.clockDateTime.substring(0, 10))}</Moment>)</strong></div></Grid> : ""}

                                                    <Grid item xs={4} md={2} lg={1}>
                                                        {userSession.idRole !== 1 ? 
                                                            <div className="clickable" onClick={() => { this.setState({ modal: { open: true, title: "Detalle de fichaje", icon: "manage_accounts", color: "blue", size: "sm", component: <TimeUpdate handler={this.updateThis} idClock={item.idClock} idUser={idUser} />, key: Math.random() } }) }}>
                                                            <Moment format="HH:mm:ss" utc local>{item.clockDateTime}</Moment>
                                                        </div>
                                                            :
                                                            <Moment format="HH:mm:ss" utc local>{item.clockDateTime}</Moment>
                                                            }
                                                    </Grid>
                                                    <Grid item xs={8} md={4} lg={2}>{item.idClockType == 1 ? "Inicio Jornada" : item.idClockType == 2 ? "Fin Jornada" : item.idClockType == 3 ? "Inicio pausa" : "Fin pausa"}</Grid>
                                            </React.Fragment>
                                            )}
                                        </Grid>
                                        }
                </div>
                <Modal key={modal.key} open={modal.open} title={modal.title} icon={modal.icon} color={modal.color} component={modal.component} size={modal.size} />
                </div>
        );
    }
}

export default TimeHistory;