import React, { useState } from "react";
import DynamicTable from "../../global/Components/dynamicTable";
import { Container, Button, Grid, Card, Icon, Tooltip } from '@material-ui/core';
import CardHeader from '../../global/Components/cardHeader';
import Modal from '../../global/Components/modal';
import DetailReport from "./Components/detailReport";
import ViewReport from "./Components/viewReport";

function Reports() {

    const [reload, setReload] = useState(0);
    const [key, setKey] = useState(Math.random());
    const [modal, setModal] = useState({
        open: false,
        title: "",
        icon: "",
        color: "",
        component: "",
        size: "",
        key: Math.random(),
        height: "65vh"
    });

    //Definición de columnas
    const columns = [
        { accessor: "idReport", Header: "Id", width: "10%" },
        { accessor: "reportTitle", width: "75%", Header: "Nombre del informe" },
        {
            Header: "Acciones", filterable: false, width: "15%", Cell: props =>
                <div>
                    <Tooltip title="Ver informe">
                        <Button variant="outlined" onClick={() => { setModal({ open: true, title: "Ver informe", icon: "visibility", color: "blue", size: "md", component: <ViewReport idReport={props.row.original.idReport} />, key: Math.random() }) }} style={{marginRight:'5px'}}><Icon>visibility</Icon></Button>
                    </Tooltip>
                    <Tooltip title="Editar informe">
                        <Button variant="outlined" onClick={() => { setModal({ open: true, title: "Configuración de informe", icon: "manage_accounts", color: "blue", size: "sm", component: <DetailReport setReload={() => setReload(Math.random())} idReport={props.row.original.idReport} />, key: Math.random() }) }}><Icon>more_horiz</Icon></Button>
                    </Tooltip>
                </div>
        }
    ];


    return (
        <Container className="reports req" maxWidth="xl">
            {/* ---------------- CABECERA -------------------- */}
            <Grid id="header" container spacing={5} padding={10}>
                <Grid item xs={12}><h2>Informes</h2></Grid>
            </Grid>
            {/* ---------------- FIN CABECERA -------------------- */}
            <Button variant="outlined" className="elementInDynamicTableHeader"
                onClick={() => { setModal({ open: true, title: "Detalle del informe", icon: "manage_accounts", color: "blue", size: "sm", component: <DetailReport setReload={() => setReload(Math.random())} idReport="0" />, key: Math.random() }) }}
            >Nuevo informe</Button>
            <Card className="people-card dynamicTableHeaderAllowElements">
                <CardHeader title="Configuración de informes" icon="history" color="red" />
                <DynamicTable reload={reload} key={key} isOrdered={true} isGlobalSearch={true} columns={columns} endpoint={"Reports/All"} />
                <Modal key={modal.key} height={modal.height} open={modal.open} title={modal.title} icon={modal.icon} color={modal.color} component={modal.component} size={modal.size} />
            </Card>
        </Container>
    )
}

export default Reports;
