import React, { Component } from "react";
import Http from "../../global/Actions/http"
import { Card, Grid, Container, Button, Link, TextField} from '@material-ui/core';
import Loading from '../../global/Components/loading';
import CardHeader from '../../global/Components/cardHeader';

class Alerts extends Component {

    constructor(props) {
        const userSession = JSON.parse(localStorage.getItem('userSession')); // Constante objeto con todos los datos del usuario que ha hecho login en la aplicación a partir de cookie

        super(props);
        this.state = {
            alerts: [], //array donde guardo las alertas
            loading: true, //para el icono de cargar
            newAlert: { // Objeto nueva solicitud de alerta
                "alertTitle": "", // Tipo de alerta
                "alertBody": "", //Contenido de la nueva alerta
                "alertLink": "", //url opcional, normalmente es un campo vacío
                "alertShowFrom": "", // Fecha inicio
                "alertShowUntil": "", // Fecha fin
                "idUser": userSession.idUser, // Id del usuario a solicitar creacion de alerta
            },
            reload: Math.random()
        };

        this.handleNewRequest = this.handleNewRequest.bind(this);
        this.postNewRequest = this.postNewRequest.bind(this);
        this.getAlerts = this.getAlerts.bind(this);
        
    }

    componentDidMount() { // Eventos que se lanzarán al cargar el componente
        this.getAlerts();
    }

    getAlerts() {
        Http.Call("GET", "Alerts/All").then(response => { // Captura de alertas
            if (!response.error) {
                this.setState({ alerts: response.data, loading: false }); // Las trasladamos al state
            }
        });
    }

    handleNewRequest(e) { // Handle para controlar en state los valores de los elementos de nueva solicitud
        e.persist(); // Mantengo persistente el estado del elemento hasta el final del controlador
        var value = e.target.value; // Vuelco en la variable value el valor de elemento
        this.setState(prevState => ({ newAlert: { ...prevState.newAlert, [e.target.name]: value } })); // Establezco en el state el valor del campo actual respetando el resto de elementos
    }

    postNewRequest() { // Mandamos la solicitud de nueva petición de alerta a la API
        Http.Call("POST", "Alerts/New", this.state.newAlert).then(response => { //Realizo la llamada POST
            if (!response.error) {
                this.setState({ newAlert: { "alertTitle": "", "alertBody": "", "alertLink": "", "alertShowFrom": "", "alertShowUntil": "" } }); // Reseteo los valores del state y en consecuencia del formulario
                this.getAlerts();// Reseteo la key reload para que recargue el componente de lista
            }
        });
    }

    deleteAlert(id) {//Borramos desde API y luego tambien en la lista a partir del id de la alerta

        Http.Call("DELETE", "Alerts/Delete/" + id).then(response => {
            if (!response.error) { //Compruebo si el servicio web me confirma que se ha eliminado la solicitud correctamente
                this.getAlerts(); //reseteo la key reload para recargar el componente de lista
            }
        });
    }

    render() {

        const { alerts, loading, newAlert, reload } = this.state; //definimos que alerts y loading tengan lo dado en el state del didmount

        return (
            
            <Container className="alerts" maxWidth="xl">
                <Http/>
                {/* ---------------- CABECERA -------------------- */}
                <Grid id="header" container spacing={5} padding={10}>
                    <Grid item xs={12}><h2>Alertas</h2></Grid>
                </Grid>
                {/* ---------------- FIN CABECERA -------------------- */}

                <Card className="people-card">
                    <CardHeader title="Nueva Alerta" icon="manage_accounts" color="red" />
                    {loading ? <Loading /> :
                        <div className="people-card-body">
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={4}>
                                    <span>Titulo de la alerta:</span><br />
                                    <TextField fullWidth type="text" id="alertTitle" name="alertTitle" value={newAlert.alertTitle} onChange={this.handleNewRequest} />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <span>Descripción:</span><br />
                                    <TextField fullWidth type="text" id="alertBody" name="alertBody" value={newAlert.alertBody} onChange={this.handleNewRequest} />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <span>Enlace (opcional):</span><br />
                                    <TextField fullWidth type="text" id="alertLink" name="alertLink" value={newAlert.alertLink} onChange={this.handleNewRequest} />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <span>Fecha inicio:</span><br />
                                    <TextField fullWidth type="date" name="alertShowFrom" id="alertShowFrom" name="alertShowFrom" value={newAlert.alertShowFrom} onChange={this.handleNewRequest} />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <span>Fecha fin:</span><br />
                                    <TextField fullWidth type="date" name="alertShowUntil" id="alertShowUntil" name="alertShowUntil" value={newAlert.alertShowUntil} onChange={this.handleNewRequest} />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Button variant="contained" color="primary" component={Link} onClick={this.postNewRequest}>Crear Nueva Alerta</Button>
                                </Grid>
                            </Grid>
                        </div>
                    }
                </Card>

                <Card className="people-card" key={reload}>
                    <CardHeader title="Registro de Alertas" icon="article" color="blue" />
                    <div className="people-card-body">
                        {loading ? <Loading /> : alerts.map(alert =>
                            <div className="people-list" key={"alert-"+alert.idAlert}>
                          <Grid container spacing={3}>
                                <Grid item xs={12} md={8}>
                                    <strong>Título: </strong> {alert.alertTitle}<br/>
                                    <strong>Contenido: </strong> {alert.alertBody}<br/>
                                        <strong>Enlace: </strong> {alert.alertLink == "" ? "Sin enlace" : alert.alertLink}
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Button component={Link} variant="contained" className="alertButton" onClick={() => { this.deleteAlert(alert.idAlert) }}>Borrar Alerta</Button>
                                </Grid>
                                </Grid>
                          </div>
                        )}
                    </div>
                </Card>

            </Container >
            
        );
    }
}

export default Alerts;