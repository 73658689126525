import React, { Component } from "react";
import loadingGif from '../../assets/images/loader.gif';

class Loading extends Component {

    constructor(props) {
        super(props);
    }


    render() {
        return (
            <div className="loader">
                <img src={ loadingGif } />
            </div>
        );
    }
}

export default Loading;
