import React, { Component } from "react";
import Http from "../../../global/Actions/http"
import { Dialog } from '@material-ui/core';

class Player extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fileName: this.props.fileName,
            folderName: this.props.folderName,
            videoContent: '',
            open: this.props.open ? this.props.open : false
        }
    }

    componentDidMount() {
        Http.Call("GET", "Help/GetVideo/" + this.state.folderName + "/" + this.state.fileName).then(response => {
            if (!response.error) {
                this.setState({ videoContent: response.data.fileContent });
            }
        })
    }


    render() {
        const { videoContent, open } = this.state;
        return (
            <Dialog
                open={open}
                fullWidth
                maxWidth="lg"
                onClose={() => { this.setState({ open: false }) }}
                >
                { videoContent &&
                    <video controls autoplay class="video">
                        <source type="video/mp4" src={"data:video/mp4;base64," + videoContent} />
                    </video>
                }
                <Http />
            </Dialog>
        );
    }
}

export default Player;
