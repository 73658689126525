import React, { Component } from "react";
import Http from "../../global/Actions/http"
import ListRequests from "./components/listRequests"
import { Container, Grid, TextField, NativeSelect, Button, InputAdornment, Card, Icon } from '@material-ui/core';
import FileBase64 from 'react-file-base64';
import Modal from '../../global/Components/modal';
import CardHeader from '../../global/Components/cardHeader';
import CalendarAbsences from "./components/calendarAbsences"
import DraftDocuments from "./components/draftDocuments";
import Vacations from "../Home/Components/Vacations";

class Requests extends Component {

    constructor(props) {
        const userSession = JSON.parse(localStorage.getItem('userSession')); // Constante objeto con todos los datos del usuario que ha hecho login en la aplicación

        super(props);
        this.state = {
            idRequestTypeParent: 0,
            requestTypeParentName: "",
            RequestsTypes: [], // Subtipo de solicitudes
            newRequest: { // Objeto nueva solicitud de ausencia
                idRequestType: 0,
                requestFrom: new Date(),
                requestTo: new Date(),
                requestAmount: 0,
                requestRemarks: "",
                requestBelongToYear: 0
            },
            BeginDate: {
                BeginRequestFrom: "",
                BeginRequestFromHour: "00:00"
            },
            EndDate: {
                EndRequestTo: "",
                EndRequestToHour: "23:59"
            },
            hours: [],
            files: [],
            fileIcon: "cloud_upload",
            reload: Math.random(),
            reloadVacations: Math.random(),
            disabled: true,
            upload: [],
            btnmessage: "REGISTRAR",
            vacationYear: new Date().getFullYear(),
            modal: {
                open: false
            }
        };
        this.handleNewRequest = this.handleNewRequest.bind(this);
        this.postNewRequest = this.postNewRequest.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.idRequestTypeParent != prevProps.match.params.idRequestTypeParent) {
            this.getTypes(this.props.match.params.idRequestTypeParent);
        }
    }

    componentDidMount() {
        this.getTypes(this.props.match.params.idRequestTypeParent);
    }

    async getTypes(idRequestTypeParent) {
        await Http.Call("GET", "Requests/Types/" + idRequestTypeParent).then(response => {
            if (!response.error) {
                this.setState({ RequestsTypes: response.data, idRequestTypeParent: idRequestTypeParent, reload: Math.random() });
            }
        });
        await this.resetState(idRequestTypeParent);
        this.checkIfReady();
    }

    resetState(idRequestTypeParent) {
        var now = new Date("2000-01-01 6:00");
        var hours = [];
        var i = 0;
        while (i < 48) {
            hours.push((now.getHours() < 10 ? "0" + now.getHours() : now.getHours()) + ":" + (now.getMinutes() == 0 ? "00" : now.getMinutes()));
            now.setMinutes(now.getMinutes() + 30);
            i++;
        }
        this.setState({
            requestTypeParentName: idRequestTypeParent == 1 ? "Ausencias" : idRequestTypeParent == 2 ? "Solicitudes" : "Documentos",
            newRequest: {
                idRequestType: idRequestTypeParent == 1 ? 11 : idRequestTypeParent == 2 ? 28 : 34,
                requestFrom: new Date(),
                requestTo: new Date(),
                requestAmount: 0,
                requestRemarks: "",
                requestBelongToYear: new Date().getFullYear()
            },
            BeginDate: {
                BeginRequestFrom: "",
                BeginRequestFromHour: "00:00"
            },
            EndDate: {
                EndRequestTo: "",
                EndRequestToHour: "23:59"
            },
            hours: hours,
            files: [],
            btnmessage: "REGISTRAR",
            modal: {
                open: false
            }
        });
    }

    checkIfReady() {
        const { newRequest, BeginDate } = this.state;
        if (this.state.idRequestTypeParent == 1) {
            if (BeginDate.BeginRequestFrom != "") { this.setState({ disabled: false }); }
            else { this.setState({ disabled: true }); }
        }
        else if (this.state.idRequestTypeParent == 2) {
            if (newRequest.requestRemarks != "") { this.setState({ disabled: false }); }
            else { this.setState({ disabled: true }); }
        }
        else if (this.state.idRequestTypeParent == 3) {
            this.setState({ disabled: false });
        }
    }

    async handleNewRequest(e) { // Handle para controlar en state los valores de los elementos de nueva solicitud

        e.persist(); // Mantengo persistente el estado del elemento hasta el final del controlador
        var value = e.target.value; // Vuelco en la variable value el valor de elemento
        if (e.target.type == "checkbox") { value = e.target.checked } // Si el elemento es un checkbox no tiene value, traigo el valor del estado del checked

        if (e.target.name.includes("Begin")) { //Control de los combos de fecha y hora desde
            await this.setState(prevState => ({ BeginDate: { ...prevState.BeginDate, [e.target.name]: value } }));
            await this.setState(prevState => ({ newRequest: { ...prevState.newRequest, requestFrom: this.state.BeginDate.BeginRequestFrom + " " + this.state.BeginDate.BeginRequestFromHour, requestBelongToYear: this.state.BeginDate.BeginRequestFrom.substring(0,4)} }));
            await this.setState(prevState => ({ EndDate: { ...prevState.EndDate, EndRequestTo: this.state.EndDate.EndRequestTo === "" ? this.state.BeginDate.BeginRequestFrom : this.state.EndDate.EndRequestTo } }));
            await this.setState(prevState => ({ newRequest: { ...prevState.newRequest, requestTo: this.state.EndDate.EndRequestTo + " " + this.state.EndDate.EndRequestToHour } }));

        }
        else if (e.target.name.includes("End")) {
            await this.setState(prevState => ({ EndDate: { ...prevState.EndDate, [e.target.name]: value } }));
            await this.setState(prevState => ({ newRequest: { ...prevState.newRequest, RequestTo: this.state.EndDate.EndRequestTo + " " + this.state.EndDate.EndRequestToHour } }));
        }
        else {
            await this.setState(prevState => ({ newRequest: { ...prevState.newRequest, [e.target.name]: value } })); // Establezco en el state el valor del campo actual respetando el resto de elementos
        }
        this.checkIfReady();
    }

    postNewRequest() { // Mandamos la solicitud de nueva petición de ausencia a la API
        this.setState({ btnmessage: 'UN MOMENTO...' });
        Http.Call("POST", "Requests/New", this.state.newRequest).then(response => { //Realizo la llamada POST
            if (!response.error) { // Compruebo si el servicio web me confirma que se ha añadido la solicitud correctamente
                this.uploadFiles(response.data.idRequest);
                this.setState({ reload: Math.random() }); // Reseteo la key reload para que recargue el componente de lista
            }
        })
    }

    async handleFiles(files) { // Handle para controlar la conversión de ficheros de input file a base64
        var sFiles = [];
        files.map(file => {
            var objfile = { "idRequest": this.state.idRequest, "fileName": file.name, "fileContent": file.base64.split("base64,")[1] } // Creamos objeto a insertar en array segun estructura POST de la API
            sFiles.push(objfile);
        })
        await this.setState({ upload: sFiles });
    }

    uploadFiles(idRequest) {
        var files = this.state.upload;
        files.map(file => {
            Http.Call("POST", "Requests/UploadFile/", { IdRequest: idRequest, FileName: file.fileName, FileContent: file.fileContent });
        })
        this.resetState(this.state.idRequestTypeParent);
    }

    render() {
        const { BeginDate, EndDate, hours, RequestsTypes, fileIcon, idRequestTypeParent, newRequest, reload, disabled, requestTypeParentName, btnmessage, modal, vacationYear, reloadVacations } = this.state; // Creo constantes de objetos a partir del state del componente
        return (
            <Container className="req" maxWidth="xl">
                <Http/>
                {/* ---------------- CABECERA -------------------- */}
                <Grid id="header" container spacing={5} padding={10}>
                    <Grid item xs={7}><h2>Gestion de {requestTypeParentName}</h2></Grid>
                    <Grid item xs={5}>
                        {
                            idRequestTypeParent == 3 ?
                                <div>
                                    <Button variant="outlined" className="float-right" color="primary" onClick={() => { this.setState({ modal: { open: true } }) }}>Plantillas de documentos</Button>
                                    <Modal key={Math.random()} open={modal.open} title="Plantillas de documentos" component={<DraftDocuments />} />
                                </div>
                                : <div></div>
                        }
                    </Grid>
                       
                </Grid>
                {/* ---------------- FIN CABECERA -------------------- */}

                {/* ---------------- FORMULARIO SOLICITUD -------------------- */}
                <Grid item xs={12} id="up">
                    <Card className="people-card">
                        <CardHeader title={"Crear nuevo registro de " + requestTypeParentName} icon="task" color="red" />
                        <div className="people-card-body">
                            <Grid container spacing={3} id="up">
                                <Grid item xs={12} sm={6} lg={2}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                    <span>Categoría:</span><br />
                                    <NativeSelect id="idRequestType" name="idRequestType" value={newRequest.idRequestType} onChange={this.handleNewRequest} fullWidth>
                                        <option value="" disabled >Selecciona motivo</option>
                                        {RequestsTypes.map(rtype => <option key={"rtype-" + rtype.idRequestType} value={rtype.idRequestType}>{rtype.requestTypeName}</option>)}
                                    </NativeSelect>
                                        </Grid>
                                        {idRequestTypeParent == 2 &&
                                            <Grid item xs={12}>
                                                <span>Importe (si procede):</span><br />
                                                <TextField id="requestAmount" name="requestAmount" InputProps={{
                                                    endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                                }} fullWidth value={newRequest.requestAmount} onChange={this.handleNewRequest} />
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                                {idRequestTypeParent == 1 &&
                                    <Grid item xs={12} sm={12} lg={4}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={8}>
                                                <span>Fecha desde:</span><br />
                                                <TextField id="BeginRequestFrom" name="BeginRequestFrom" type="date" fullWidth value={BeginDate.BeginRequestFrom} onChange={this.handleNewRequest} />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <span>Hora desde (opcional):</span><br />
                                                <NativeSelect id="BeginRequestFromHour" name="BeginRequestFromHour" fullWidth value={BeginDate.BeginRequestFromHour} onChange={this.handleNewRequest}>
                                                    <option value="00:00">--:--</option>
                                                    {hours.map((hour, i) => <option key={"hourf-" + i} value={hour}>{hour}</option>)}
                                                </NativeSelect>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <span>Fecha hasta:</span><br />
                                                <TextField id="EndRequestTo" name="EndRequestTo" type="date" fullWidth value={EndDate.EndRequestTo} onChange={this.handleNewRequest} disabled={BeginDate.BeginRequestFrom != "" ? false : true} inputProps={{ min: BeginDate.BeginRequestFrom }} />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <span>Hora hasta (opcional):</span><br />
                                                <NativeSelect fullWidth id="EndRequestToHour" name="EndRequestToHour" value={EndDate.EndRequestToHour} onChange={this.handleNewRequest} disabled={BeginDate.BeginRequestFrom != "" ? false : true}>
                                                    <option value="23:59">--:--</option>
                                                    {hours.map((hour, i) => <option key={"hourt-" + i} value={hour}>{hour}</option>)}
                                                </NativeSelect>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }

                                <Grid item xs={12} sm={6} lg={idRequestTypeParent == 1 ? 6 : 10}>
                                    <span>Observaciones:</span><br />
                                    <TextField id="requestRemarks" name="requestRemarks" multiline className="abs-comments" fullWidth value={newRequest.requestRemarks} onChange={this.handleNewRequest} />
                                </Grid>
                                <Grid item xs={12} lg={8}>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={2}>
                                    <Button fullWidth variant="contained" startIcon={<Icon>{fileIcon}</Icon>} component="label" className="upload">Subir justificante
                                        <FileBase64 multiple={true} onDone={this.handleFiles.bind(this)} />
                                    </Button>                                </Grid>
                                <Grid item xs={12} sm={6} lg={2}>
                                    <Button fullWidth variant="contained" color="primary" onClick={this.postNewRequest} disabled={disabled} >{btnmessage}</Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Card>
                </Grid>
                
                {/* ---------------- FIN FORMULARIO -------------------- */}

                
                <Grid container spacing={4} id="down">
                    <Grid item xs={12} sm={idRequestTypeParent == 1 ? 4 : 12}>
                        <Card className="people-card">
                            <CardHeader title={"Mis " + requestTypeParentName} icon="event" color="purple" />
                            <div className="people-card-body">
                                {idRequestTypeParent > 0 &&
                                    <ListRequests idRequestTypeParent={idRequestTypeParent} key={reload} />
                                }
                            </div>
                        </Card>
                    </Grid>
                    {/* ---------------- LISTADO  -------------------- */}
                    {idRequestTypeParent==1 &&
                    <Grid item xs={12} sm={4}>
                        <Card className="people-card">
                            <CardHeader title="Mi Calendario" icon="date_range" color="brown" />
                            <div className="people-card-body">
                                <CalendarAbsences numberOfMonths={1} key={reload} />
                            </div>
                        </Card>
                    </Grid>
                    }
                    {idRequestTypeParent == 1 &&
                    <Grid item xs={12} sm={4}>
                        <Card className="people-card">
                            <Grid container className="people-card-header">
                                <Grid item xs={6}><Icon style={{ color: 'red', float: 'left', marginRight: '10px' }}>local_airport</Icon><div className="people-card-header-title">
                                    {'Vacaciones ' + vacationYear}
                                </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <Icon className="float-right arrow" onClick={() => { this.setState({ vacationYear: this.state.vacationYear + 1, reloadVacations: Math.random() }) }}>arrow_right</Icon>
                                    <Icon className="float-right arrow" onClick={() => { this.setState({ vacationYear: this.state.vacationYear - 1, reloadVacations: Math.random() }) }} >arrow_left</Icon>

                                </Grid>
                            </Grid>
                            <div className="people-card-body">
                                <Vacations key={reload} year={vacationYear} />
                            </div>
                        </Card>
                    </Grid>
                    }

                    {/* ---------------- FIN LISTADO DE AUSENCIAS -------------------- */}
                </Grid>
                

            </Container>
        );
    }
}

export default Requests;
