import React, { Component } from "react";
import Http from "../../global/Actions/http";
import { Icon, Container, Grid, TextField, NativeSelect, Button, Checkbox, Card, Snackbar } from '@material-ui/core';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment';
import CardHeader from '../../global/Components/cardHeader';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Loading from '../../global/Components/loading';
import Modal from '../../global/Components/modal';
import DetailRequest from "../Requests/components/detailRequest"

class AbsencesTeam extends Component {

    constructor(props) {
        const userSession = JSON.parse(localStorage.getItem('userSession')); // Constante objeto con todos los datos del usuario que ha hecho login en la aplicación a partir de cookie

        super(props);
        this.state = {
            events: null,
            today: new Date(),
            modal: {
                open: false,
                title: "",
                icon: "",
                color: "",
                component: "",
                size: "",
                key: Math.random()
            }
        }
        this.showDetails = this.showDetails.bind(this);
        this.getData = this.getData.bind(this);
    }

    async getData() {
        var users = [];
        var events = [];
        var i = 1;
        await Http.Call("GET", "Users/MyTeam").then(response => {
            if (!response.error) {
                users = response.data;
            }
        });
        await Promise.all(users.map(async (item) => { //Recupero las ausencias de cada usuario de manera async para mostrarlas todas de golpe.
            Http.Call("POST", "Requests/List/", { IdRequestTypeParent: 1, IdUser: item.idUser }).then(response => {
                if (!response.error) {
                    response.data.map(absence => {
                        var abs = {
                            id: absence.idRequest,
                            index: i,
                            title: absence.userFullname,
                            type: absence.requestTypeName,
                            start: moment(absence.requestFrom).toDate(),
                            end: moment(absence.requestTo).toDate(),
                            desc: absence.requestTypeName
                        }
                        if (absence.idRequestStatus == 1) {
                            events.push(abs);
                        }
                    });
                    i > 4 ? i = 1 : i++;
                }
            });
        }));

        this.setState({ events: events });
    }

    async componentDidMount() {
        this.getData();
    }

    showDetails(event) {
        this.setState({ modal: { open: true, title: "Detalle de solicitud de ausencia", icon: "manage_accounts", color: "darkblue", size: "sm", component: <DetailRequest updateParent={this.getData} idRequest={event.id} />, key: Math.random() } })
    }

    customEvent({ event }) {
        return (
            <span>
                <small style={{ color: "yellow" }}>[{event.type}]</small> <small style={{ textTransform: "capitalize" }}>{ event.title.toLowerCase() }</small>
            </span>
        )
    }

    render() {
        const { today, events, modal } = this.state;
        const colors = ["#1a2c50", "#0897c0", "#586366", "#4a447e"];
        const localizer = momentLocalizer(moment)
        return (
            <Container className="req" maxWidth="xl">
                {/* ---------------- CABECERA -------------------- */}
                <Grid id="header" container spacing={5} padding={10}>
                    <Grid item xs={12}><h2>Calendario de ausencias de mi equipo</h2></Grid>
                </Grid>
                {/* ---------------- FIN CABECERA -------------------- */}

                <Grid item xs={12} id="up">
                    <Card className="people-card">
                        <CardHeader title="Calendario de mi equipo" icon="event" color="darkblue" />
                        <div className="people-card-body">
                            {events != null ?
                                <Calendar
                                    localizer={localizer}
                                    events={events}
                                    startAccessor='start'
                                    endAccessor='end'
                                    eventPropGetter={(event) => {
                                        const backgroundColor = colors[event.index];
                                        return { style: { backgroundColor } }
                                    }}
                                    style={{ height: "65vh" }}
                                    messages={{
                                        agenda: 'Agenda',
                                        allDay: 'Día completo',
                                        month: 'Mes',
                                        day: 'Día',
                                        today: 'Hoy',
                                        previous: 'Anterior',
                                        next: 'Siguiente',
                                        date: 'Fecha',
                                        noEventsInRange: 'Sin eventos',
                                        time: 'Hora',
                                        tomorrow: 'Mañana',
                                        week: 'Semana',
                                        work_week: 'Días laborables',
                                        yesterday: 'Ayer'
                                    }}
                                    components={{
                                        event: this.customEvent
                                    }}
                                    onSelectEvent={(this.showDetails)}
                                    defaultDate={today}
                                />
                                : <Loading />
                            }
                        </div>
                    </Card>
                </Grid>
                <Modal key={modal.key} open={modal.open} title={modal.title} icon={modal.icon} color={modal.color} component={modal.component} size={modal.size} />
            </Container>
        );
    }
}

export default AbsencesTeam;
