import React, { Component } from "react";
import { Container, Grid, Card } from '@material-ui/core';

import TimeAction from "./Components/TimeAction"
import TimeHistory from "./Components/TimeHistory"
import CardHeader from '../../global/Components/cardHeader';
import Modal from '../../global/Components/modal';


class TimeControl extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Container className="timecontrol" maxWidth="xl">
                {/* ---------------- CABECERA -------------------- */}
                <Grid id="header" container spacing={5} padding={10}>
                    <Grid item xs={12}><h2>Control horario</h2></Grid>
                </Grid>
                {/* ---------------- FIN CABECERA -------------------- */}
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={4}>
                        <Card className="people-card">
                            <CardHeader title="Control horario" icon="schedule" color="green" />
                            <div className="people-card-body">
                                <TimeAction />
                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Card className="people-card events">
                            <CardHeader title="Historial de eventos" icon="date_range" color="blue" />
                            <div className="people-card-body">
                                <TimeHistory />
                            </div>
                        </Card>
                    </Grid>
                </Grid>
               
             </Container>
        );
    }
}

export default TimeControl;