import React, { useState, useEffect } from "react";
import DynamicTable from "../../global/Components/dynamicTable";
import { ButtonGroup, Container, Button, Grid, Card, Icon, Tooltip } from '@material-ui/core';
import CardHeader from '../../global/Components/cardHeader';
import Modal from '../../global/Components/modal';
import TimeHistory from '../TimeControl/Components/TimeHistory'
import ListRequests from "../Requests/components/listRequests"
import Profile from "../Profile"
import Moment from 'react-moment';
import VacationsResume from "./components/VacationsResume"
import { useParams } from "react-router-dom";
import Candidato from '../../assets/images/generic.png';

function ListUsers() {

    const userSession = JSON.parse(localStorage.getItem('userSession'));
    const endpoint = useParams().filter;

    const [reload, setReload] = useState(0);
    const [key, setKey] = useState(Math.random());
    const [filter, setFilter] = useState(1);
    const [tableFilter, setTableFilter] = useState([
        {
            SearchField: "UserActive",
            SearchValue: "1",
            SearchMode: 'Equal'
        }
    ]);
    const [modal, setModal] = useState({
        open: false,
        title: "",
        icon: "",
        color: "",
        component: "",
        size: "",
        key: Math.random(),
        height: "65vh"
    });

    useEffect(() => {
        if (filter == 2) { setTableFilter([]); }
        else {
            setTableFilter([
                {
                    SearchField: "UserActive",
                    SearchValue: ""+filter+"",
                    SearchMode: 'Equal'
                }
            ]);
        }
        setKey(Math.random());
    }, [filter]);

    
    //Definición de columnas

    const columns = [
        { accessor: "idUser", width: "2%", Header: "Id", },
        { accessor: "userImage", width: "3%", Header: "Imagen", filterable: false, Cell: props => <img src={props.value ? "data:image/jpg;base64," + props.value : Candidato} className="listUserImage"></img> },
        { accessor: "userFullname", width: "20%", Header: "Nombre completo"},
        { accessor: "departmentName", width: "15%",Header: "Departamento"},
        { accessor: "userOffice", width: "15%",Header: "Ubicación"},
        { accessor: "userFrom", width: "10%", Header: "Fecha Alta", Cell: props => <Moment format="DD/MM/yyyy">{props.value}</Moment> },
        {
            Header: "Acciones", width: "35%", filterable: false, Cell: props =>
                <div className="ListUsersession_actions"><nobr>
                    {userSession.idRole > 2 ?
                        <Tooltip title="Ver Perfil">
                            <Button variant="outlined" onClick={() => { setModal({ open: true, title: "Editar perfil de empleado", icon: "manage_accounts", color: "#303F9F", size: "md", component: <Profile setReload={() => setReload(Math.random())} idUser={props.row.original.idUser} />, key: Math.random() }) }}><Icon>manage_accounts</Icon></Button>
                        </Tooltip>
                        : <></>
                    }
                    <Tooltip title="Control horario">
                        <Button variant="outlined" onClick={() => { setModal({ open: true, height: "65vh", title: "Control horario de " + props.row.original.userFullname, icon: "date_range", color: "blue", size: "lg", component: <TimeHistory setReload={() => setReload(Math.random())} idUser={props.row.original.idUser} />, key: Math.random() }) }}><Icon>schedule</Icon></Button>
                    </Tooltip>
                    <Tooltip title="Ausencias">
                        <Button variant="outlined" onClick={() => { setModal({ open: true, height: "85vh", title: "Solicitudes de ausencia de " + props.row.original.userFullname, icon: "date_range", color: "blue", size: "md", component: <VacationsResume  idUser={props.row.original.idUser} />, key: Math.random() }) }}><Icon>flight</Icon></Button>
                    </Tooltip>
                    <Tooltip title="Solicitudes">
                        <Button variant="outlined" onClick={() => { setModal({ open: true, height: "65vh", title: "Solicitudes de " + props.row.original.userFullname, icon: "question_answer", color: "blue", size: "sm", component: <ListRequests idRequestTypeParent={2} idUser={props.row.original.idUser} />, key: Math.random() }) }}><Icon>question_answer</Icon></Button>
                    </Tooltip>
                    <Tooltip title="Ficheros">
                        <Button variant="outlined" onClick={() => { setModal({ open: true, height: "65vh", title: "Ficheros subidos por " + props.row.original.userFullname, icon: "date_range", color: "blue", size: "sm", component: <ListRequests idRequestTypeParent={3} idUser={props.row.original.idUser} />, key: Math.random() }) }}><Icon>description</Icon></Button>
                    </Tooltip></nobr>
                </div>
        }
    ];

    const customOrder = [
        {
            orderField: "idUser",
            orderAsc: true,
        }
    ];

    return (
        <Container className="employees" maxWidth="xl">
            {/* ---------------- CABECERA -------------------- */}
            <Grid id="header" container spacing={5} padding={10}>
                <Grid item xs={8}><h2>Gestión de empleados</h2></Grid>
                <Grid item xs={4}>
                    {userSession.idRole == 4 && !userSession.activeDirectory ?
                        <Button variant="outlined" className="float-right" onClick={() => {
                            setModal({
                                open: true, title: "Dar de alta nuevo empleado", icon: "manage_accounts", color: "#303F9F", size: "md", component: <Profile setReload={() => { setReload(Math.random()); setModal({ open: false }) }} />, key: Math.random()
                            })
                        }}>DAR DE ALTA EMPLEADO</Button>
                        : null
                    }
                </Grid>
            </Grid>
            {/* ---------------- FIN CABECERA -------------------- */}
            <ButtonGroup className="grouplist" size="small">
                <Button onClick={() => { setFilter(1) }} className={filter == 1 ? "groupActive" : "activos"}>Activos</Button>
                <Button onClick={() => { setFilter(0) }} className={filter == 0 ? "groupActive" : "inactivos"}>Inactivos</Button>
                <Button onClick={() => { setFilter(2) }} className={filter == 2 ? "groupActive" : "todos"}>Todos</Button>
            </ButtonGroup>
            <Card className="people-card">
                <CardHeader title="Listado de empleados" icon="manage_accounts" color="red" />
                <DynamicTable customOrder={customOrder} customFilter={tableFilter} key={key} reload={reload} isGlobalSearch={true} isOrdered={true} isColumnsSearch={false} columns={columns} endpoint={"Users/" + endpoint} />
                <Modal key={modal.key} height={modal.height} open={modal.open} title={modal.title} icon={modal.icon} color={modal.color} component={modal.component} size={modal.size} />
            </Card>
        </Container>
    )
}

export default ListUsers;