import React, { Component } from "react";
import Http from "../../../global/Actions/http"
import { TextField, Grid, Button, NativeSelect } from '@material-ui/core';
import moment from "moment";

class TimeUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            idClock: this.props.idClock,
            Clock: {
                clockDateTime: null,
                idClockType: 1
            },
            newClock: {
                "idUser": this.props.idUser,
                "clockDateTime": null,
                "idClockType": 0
            }
        }

        this.handleClock = this.handleClock.bind(this);
        this.postClock = this.postClock.bind(this);
        this.deleteClock = this.deleteClock.bind(this);
    }

    async componentDidMount() {
        if (this.state.idClock!=null) {
            await Http.Call("GET", "Clock/AdminDetails/" + this.state.idClock).then(response => {
                if (!response.error) {
                    this.setState({ Clock: response.data });
                }
            })
            var utclocal = moment.utc(this.state.Clock.clockDateTime).local().format('YYYY-MM-DDTHH:mm:ss');
            this.setState(prevState => ({ Clock: { ...prevState.Clock, clockDateTime: utclocal } }));
        }
    }

    async handleClock(e) {
        e.persist();
        var value = e.target.value;
        await this.setState(prevState => ({ Clock: { ...prevState.Clock, [e.target.name]: value } }));
    }

    async postClock() {
        var localutc = moment(this.state.Clock.clockDateTime).utc().format();
        await this.setState({
            newClock: {
                idUser: this.state.newClock.idUser,
                clockDateTime: localutc,
                idClockType: this.state.Clock.idClockType
            }
        });
        if (this.state.idClock == null) {
            Http.Call("POST", "Clock/AdminNew", this.state.newClock).then(response => { //Realizo la llamada POST
                if (!response.error) {
                    this.props.handler();
                }
            })
        } else {
            Http.Call("PUT", "Clock/AdminUpdate/" + this.state.idClock, this.state.newClock).then(response => { //Realizo la llamada POST
                if (!response.error) {
                    this.props.handler();
                }
            })
        }
    }

    deleteClock() {
        Http.Call("DELETE", "Clock/Delete/" + this.state.idClock).then(response => { //Realizo la llamada POST
            if (!response.error) {
                this.props.handler();
            }
        })
    }

    render() {
        const { Clock, idClock, snackbar } = this.state;
        return (
            <div className="timeupdate">
                <Http/>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <TextField id="clockDateTime" name="clockDateTime" type="datetime-local" value={Clock.clockDateTime} onChange={this.handleClock} fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <NativeSelect id="idClockType" name="idClockType" value={Clock.idClockType} onChange={this.handleClock} fullWidth>
                            <option value="1">Inicio Jornada</option>
                            <option value="3">Inicio Pausa</option>
                            <option value="4">Fin Pausa</option>
                            <option value="2">Fin Jornada</option>
                        </NativeSelect>
                    </Grid>
                    {idClock != null ?
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" style={{ marginTop: "15px" }} onClick={this.postClock} className="float-right">Guardar</Button>
                            <Button variant="outlined" style={{ marginTop: "15px" }} onClick={this.deleteClock}>Eliminar</Button>
                        </Grid>
                        :
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" style={{ marginTop: "15px" }} onClick={this.postClock} className="float-right">Crear</Button>
                        </Grid>
                        }
                </Grid>
            </div>
        );
    }
}

export default TimeUpdate;