import React, { useState } from "react";
import DynamicTable from "../../global/Components/dynamicTable";
import { Container, Button, Grid, Card, Icon, Tooltip } from '@material-ui/core';
import CardHeader from '../../global/Components/cardHeader';
import Modal from '../../global/Components/modal';
import DetailWorkplace from "./Components/detailWorkplace";

function Workplaces() {

    const [reload, setReload] = useState(0);
    const [key, setKey] = useState(Math.random());
    const [modal, setModal] = useState({
        open: false,
        title: "",
        icon: "",
        color: "",
        component: "",
        size: "",
        key: Math.random(),
        height: "65vh"
    });

    //Definición de columnas
    const columns = [
        { accessor: "idWorkplace", Header: "Id", width: "10%" },
        { accessor: "workplaceName", width: "30%", Header: "Nombre del Centro" },
        { accessor: "workplaceCity", width: "30%", Header: "Ciudad" },
        { accessor: "workplaceCode", width: "20%", Header: "Cód. Centro" },
        {
            Header: "Acciones", filterable: false, width: "10%", Cell: props =>
                <div>
                    <Tooltip title="Ver detalles">
                        <Button variant="outlined" onClick={() => { setModal({ open: true, title: "Configuración de departamento", icon: "manage_accounts", color: "blue", size: "sm", component: <DetailWorkplace setReload={() => setReload(Math.random())} idWorkplace={props.row.original.idWorkplace} />, key: Math.random() }) }}><Icon>more_horiz</Icon></Button>
                    </Tooltip>
                </div>
        }
    ];


    return (
        <Container className="workplaces req" maxWidth="xl">
            {/* ---------------- CABECERA -------------------- */}
            <Grid id="header" container spacing={5} padding={10}>
                <Grid item xs={12}><h2>Centros de trabajo</h2></Grid>
            </Grid>
            {/* ---------------- FIN CABECERA -------------------- */}
            <Button variant="outlined" className="elementInDynamicTableHeader"
                onClick={() => { setModal({ open: true, title: "Configuración de departamento", icon: "manage_accounts", color: "blue", size: "sm", component: <DetailWorkplace setReload={() => setReload(Math.random())} idWorkplace="0" />, key: Math.random() }) }}
            >Nuevo centro de trabajo</Button>
            <Card className="people-card dynamicTableHeaderAllowElements">
                <CardHeader title="Configuración de centros de trabajo" icon="history" color="red" />
                <DynamicTable reload={reload} key={key} isOrdered={true} isGlobalSearch={true} columns={columns} endpoint={"Workplaces/All"} />
                <Modal key={modal.key} height={modal.height} open={modal.open} title={modal.title} icon={modal.icon} color={modal.color} component={modal.component} size={modal.size} />
            </Card>
        </Container>
    )
}

export default Workplaces;