import React, { Component } from "react";
import Http from "../../../global/Actions/http"
import { Button, Grid, Icon } from '@material-ui/core';
import Loading from '../../../global/Components/loading';
import Moment from 'react-moment';

class TimeAction extends Component {

    constructor(props) { //Clase Lista de Usuarios
        super(props);
        const userSession = JSON.parse(localStorage.getItem('userSession')); // Constante objeto con todos los datos del usuario que ha hecho login en la aplicación
        this.state = {
            geo: {}, //Objeto que constrola el geoposicionamiento por IP desde el cual fichar
            today: [], //Objeto que contiene el historial de eventos del dia en curso
            lastId: 0, //Ultimo evento producido
            userSession: userSession,
            total: new Date("1900-01-01 00:00:00"),
            lastEvent: 0,
            timer: 0,
            loading: true
        }
        this.getToday = this.getToday.bind(this);
        this.postTimeEvent = this.postTimeEvent.bind(this);
        this.getTotalTodayTime = this.getTotalTodayTime.bind(this);
    }

    componentDidMount() {
        this.getToday();
        this.loadClock();
    }

    componentWillUnmount() {
        window.clearInterval(window.timeAction);
    }

    loadClock() {
        if (window.timeAction != undefined && window.timeAction != 'undefined') {
            window.clearInterval(window.timeAction);
        }
        window.timeAction = setInterval(
            () => this.setState({ timer: this.state.timer + 1 }),
            1000
        );
    }

    getToday() {
        Http.Call("GET", "Clock/Today").then(response => {
            if (!response.error) {
                this.setState({ today: response.data, loading: false }); //Recupero todos los eventos del día en curso
                this.getTotalTodayTime(); // Llamo a función que calcula el total del día
                if (this.state.today.length > 0) { //Si hay eventos
                    this.setState({ lastId: this.state.today[this.state.today.length - 1].idClockType }); //Guardo cual ha sido el último evento del día
                }
            }
        });
    }

    getTotalTodayTime() { //Calculamos el total trabajo en el día actual
        var sum = 0; //Variable de milisegundos a sumar (basado en tiempo trabajo)
        var deduct = 0; //Variable de milisegundos a restar (basado en pausas)
        var start = null; //Hora de inicio de la jornada
        var pausestart = null; //Hora de inicio de la pausa

        let lastEvent = 0;
        this.state.today.map(data => { //Lógica de calculo de totales
            if (data.idClockType == 1) { lastEvent = 1; start = new Date(data.clockDateTime); } // entrada
            if (data.idClockType == 2) { lastEvent = 2; sum = sum + (new Date(data.clockDateTime) - start); } // salida
            if (data.idClockType == 3) { lastEvent = 3; pausestart = new Date(data.clockDateTime); } // pausa 
            if (data.idClockType == 4) { lastEvent = 4; deduct = deduct + (new Date(data.clockDateTime) - pausestart) } // regreso
        })

        var now = new Date();
        now.setMinutes(now.getMinutes() + now.getTimezoneOffset())
        var total = new Date("1900-01-01T00:00:00");
        if (lastEvent == 1) { //Inicio jornada
            total = total.setTime(total.getTime() + (now - start));
            this.setState({ total: total });
        } else if (lastEvent == 2) { //Fin jornada
            total = total.setTime(total.getTime() + (sum - deduct));
            this.setState({ total: total });
        } else if (lastEvent == 3) { //Inicio pausa
            total = total.setTime(total.getTime() + ((pausestart - start) - deduct));
            this.setState({ total: total });
        } else if (lastEvent == 4) { //Fin pausa
            total = total.setTime(total.getTime() + (now - start) + sum - deduct);
            this.setState({ total: total });
        }
    }

    postTimeEvent(IdClockType) {
        Http.Call("GET", "Clock/New/" + IdClockType).then(response => { //Realizo la llamada POST
            if (!response.error) {
                this.getToday();
            }
        })
    }

    render() {
        const { lastId, today, total, timer, loading } = this.state;
        const typeStatus = ['Inicio Jornada', 'Fin Jornada', 'Inicio Pausa', 'Fin Pausa']
        return (
            <div>
                { loading ? <Loading /> :
                <div>
                <Grid container className="time" spacing={0}>
                    <Grid item xs={3} className="actionBtn">
                    <Button className="timebtn start" startIcon={<Icon>play_arrow</Icon>} disabled={lastId != 0 && lastId != 2} onClick={() => this.postTimeEvent(1)}>Inicio jornada</Button>
                </Grid>
                    <Grid item xs={3} className="actionBtn">
                    <Button className="timebtn pause" startIcon={<Icon>restaurant_menu</Icon>} disabled={lastId != 1 && lastId != 4} onClick={() => this.postTimeEvent(3)}>Realizar pausa</Button>
                </Grid>
                    <Grid item xs={3} className="actionBtn">
                    <Button className="timebtn endpause" startIcon={<Icon>restart_alt</Icon>} disabled={lastId != 3} onClick={() => this.postTimeEvent(4)}>Finalizar pausa</Button>
                </Grid>
                    <Grid item xs={3} className="actionBtn">
                    <Button className="timebtn end" startIcon={<Icon>how_to_reg</Icon>} disabled={lastId != 1 && lastId != 4} onClick={() => this.postTimeEvent(2)}>Fin de jornada</Button>
                </Grid>
            </Grid>
            <Grid container className="time">
                <Grid item xs={12}>
                    <Grid container className="today">
                        <Grid item xs={6}><strong>Mi jornada de hoy:</strong></Grid>
                        <Grid item xs={6}><Moment className="float-right current-time" format="HH:mm:ss">
                            {lastId === 1 || lastId === 4 ? total+(timer*1000) : total}
                        </Moment></Grid>
                    </Grid>
                        <div className="event-list">
                        { today.length > 0 ?
                        today.map((event,i) =>
                            <div className="people-list" key={"evn-"+i}><strong><Moment format="HH:mm:ss" utc local>{event.clockDateTime}</Moment></strong> - {typeStatus[event.idClockType - 1]}</div>
                            )
                        : <div className="people-list">La jornada no ha comenzado</div>
                        }
                    </div>
                    </Grid>

                </Grid>
                </div>
                }
            </div>
                

        );
    }
}

export default TimeAction;