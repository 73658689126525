import React, { Component } from "react";
import { Card, Grid, Container, Button, Link, TextField, Icon } from '@material-ui/core';
import Http from "../../../global/Actions/http"
import Loading from '../../../global/Components/loading';
import Moment from 'react-moment';
import FileBase64 from 'react-file-base64';

class detailNew extends Component {

    constructor(props) {
        const userSession = JSON.parse(localStorage.getItem('userSession'));
        super(props);
        this.state = {
            idNew: this.props.idNew,
            loading: true,
            newNoticia: null,
            idUser: userSession.idUser,
            reload: Math.random()
        }
        this.handleUpdate = this.handleUpdate.bind(this);
        this.getNew = this.getNew.bind(this);
        this.updateNew = this.updateNew.bind(this);
    }

    componentDidMount() { // Eventos que se lanzarán al cargar el componente
        this.getNew();
    }

    getNew() {
        const userSession = JSON.parse(localStorage.getItem('userSession'));
        Http.Call("GET", "News/Details/" + this.state.idNew).then(response => { // Captura de ausencias guardadas del usuario en curso
            if (!response.error) {
                this.setState({ newNoticia: response.data, loading: false }); // Las trasladamos al state
            }
        });
    }

    async handleUpdate(e) { // Handle para controlar en state los valores de los elementos de nueva solicitud
        e.persist(); // Mantengo persistente el estado del elemento hasta el final del controlador
        var value = e.target.value; // Vuelco en la variable value el valor de elemento
        await this.setState(prevState => ({ newNoticia: { ...prevState.newNoticia, [e.target.name]: value } })); // Establezco en el state el valor del campo actual respetando el resto de elementos
    }

    updateNew() {
        Http.Call("PUT", "News/Update/" + this.state.idNew, this.state.newNoticia).then(response => {
            if (!response.error) { //cerramos modal porque se han cambiado con exito los datos
                this.props.updateParent();
            }
        });
    }

    render() {
        const { newNoticia, loading} = this.state; // Creo constantes de objetos a partir del state del componente
        return (
            <div>
                { loading ? <Loading /> :
                    <>
                        <Grid container spacing={3}>
                            <Grid class="newNoticia" container spacing={5}>
                                <Grid item xs={12} md={12}><h1>Noticia</h1></Grid>
                            </Grid>
                                <Grid item xs={12} md={12}>
                                    <strong>Titulo:</strong><br />
                                    <TextField fullWidth type="text" id="newTitle" name="newTitle" value={newNoticia.newTitle} onChange={this.handleUpdate} />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                <strong>Descripción:</strong><br />
                                    <TextField fullWidth multiline variant="filled" type="text" id="newBody" name="newBody" value={newNoticia.newBody} onChange={this.handleUpdate} />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                <strong>Enlace (opcional):</strong><br />
                                    <TextField fullWidth type="text" id="newLink" name="newLink" value={newNoticia.newLink} onChange={this.handleUpdate} />
                                </Grid>
                                <Grid item xs={12} md={8}>
                                <strong>Fecha:</strong><br />
                                <TextField fullWidth type="date" id="newDate" name="newDate" value={newNoticia.newDate.substring(0,10)} onChange={this.handleUpdate} />
                                </Grid>
                            <Grid item xs={12} md={4}>
                                <Button variant="contained" color="primary" component={Link} onClick={this.updateNew}>Guardar Datos</Button>
                                </Grid>
                        </Grid>
                    </>
                }
            </div>
        );
    }
}

export default detailNew;