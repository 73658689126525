import React, { Component } from "react";
import { TextField, Button, NativeSelect, Grid } from '@material-ui/core';
import Http from "../../../global/Actions/http"
import Loading from '../../../global/Components/loading';
import Moment from 'react-moment';
import DynamicTable from "../../../global/Components/dynamicTable";

class detailProject extends Component {

    constructor(props) {
        const userSession = JSON.parse(localStorage.getItem('userSession'));
        super(props);
        this.state = {
            idProject: this.props.idProject,
            users: [],
            loading: true,
            project: {
                projectName:''
            },
            idUser: userSession.idUser,
            areyousure: false
        }
        this.handleUpdate = this.handleUpdate.bind(this);
        this.getProject = this.getProject.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.updateProject = this.updateProject.bind(this);
        this.deleteProject = this.deleteProject.bind(this);
    }

    componentDidMount() { // Eventos que se lanzarán al cargar el componente
        this.getUsers();
        this.getProject();
    }

    getUsers() {
        Http.Call("GET", "Users/All").then(response => { // Captura de ausencias guardadas del usuario en curso
            if (!response.error) {
                this.setState({ users: response.data });
            }
        });
    }

    getProject() {
        if (this.state.idProject > 0) {
            Http.Call("GET", "Projects/Details/" + this.state.idProject).then(response => { // Captura de ausencias guardadas del usuario en curso
                if (!response.error) {
                    this.setState({ project: response.data, loading: false }); // Las trasladamos al state
                }
            });
        } else {
            this.setState({ Project: { ProjectName: '' }, loading: false })
        }
    }

    updateProject() {
        if (this.state.idProject > 0) { //Crear
            Http.Call("PUT", "Projects/Update/" + this.state.idProject, this.state.project).then(response => { // Captura de ausencias guardadas del usuario en curso
                if (!response.error) {
                    this.getProject();
                    this.props.setReload ? this.props.setReload() : null;
                }
            });
        }
        else
        {
            Http.Call("POST", "Projects/New", this.state.project).then(response => { // Captura de ausencias guardadas del usuario en curso
                if (!response.error) {
                    this.setState({ idProject: response.data.idProject })
                    this.getProject();
                    this.props.setReload ? this.props.setReload() : null;
                }
            });
        }
    }

    deleteProject() {
        Http.Call("DELETE", "Projects/Delete/" + this.state.idProject).then(response => { // Captura de ausencias guardadas del usuario en curso
            if (!response.error) {
                this.setState({ idProject: 0, areyousure: false });
                this.getProject();
                this.props.setReload ? this.props.setReload() : null;
            }
        });
    }

    async handleUpdate(e) { // Handle para controlar en state los valores de los elementos de nueva solicitud
        e.persist(); // Mantengo persistente el estado del elemento hasta el final del controlador
        var value = e.target.value; // Vuelco en la variable value el valor de elemento
        await this.setState(prevState => ({ project: { ...prevState.project, [e.target.name]: value } })); // Establezco en el state el valor del campo actual respetando el resto de elementos
    }

    render() {
        const { project, loading, areyousure } = this.state; // Creo constantes de objetos a partir del state del componente
        return (
                <div>
                { loading ? <Loading /> :
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <strong>Nombre del proyecto:</strong><br />
                                <TextField id="projectName" name="projectName" value={project.projectName} onChange={this.handleUpdate} fullWidth />
                            </Grid>
                            <Grid item xs={6}>
                                <strong>Código:</strong><br />
                                <TextField id="projectCode" name="projectCode" value={project.projectCode} onChange={this.handleUpdate} fullWidth />
                            </Grid>
                            <Grid item xs={6}>
                                <strong>Empresa:</strong><br />
                                <TextField id="projectCompany" name="projectCompany" value={project.projectCompany} onChange={this.handleUpdate} fullWidth />
                            </Grid>
                            <Grid item xs={6}>
                                <strong>Responsable (externo o interno):</strong><br />
                                <TextField id="projectManager" name="projectManager" value={project.projectManager} onChange={this.handleUpdate} fullWidth />
                            </Grid>
                            <Grid item xs={6}>
                                <strong>E-Mail responsable:</strong><br />
                                <TextField id="projectManagerEmail" name="projectManagerEmail" value={project.projectManagerEmail} onChange={this.handleUpdate} fullWidth />
                            </Grid>
                            <Grid item xs={6}>
                                <strong>Teléfono responsable:</strong><br />
                                <TextField id="projectManagerPhone" name="projectManagerPhone" value={project.projectManagerPhone} onChange={this.handleUpdate} fullWidth />
                            </Grid>
                            <Grid item xs={6}>
                                <strong>Fecha desde:</strong><br />
                                <Grid item xs={12} md={4}>
                                </Grid>
                                <TextField id="projectFrom" name="projectFrom" type="date" value={project.projectFrom == null ? "" : project.projectFrom.substring(0, 10)} onChange={this.handleUpdate} fullWidth />
                            </Grid>
                            <Grid item xs={6}>
                                <strong>Fecha hasta:</strong><br />
                                <TextField id="projectTo" name="projectTo" type="date" value={project.projectTo == null ? "" : project.projectTo.substring(0, 10)} onChange={this.handleUpdate} fullWidth />
                            </Grid>
                            {project.idProject > 0 ?
                            <>
                            <Grid item xs={12}>
                                        <Button className="float-right" variant="outlined" onClick={() => { areyousure ? this.deleteProject() : this.setState({ areyousure: true }); }} style={{ marginLeft: '10px' }}>{!areyousure ? "Eliminar" : "¿Seguro?"}</Button>
                                        <Button className="float-right" variant="outlined" onClick={this.updateProject}>Actualizar</Button>
                                        
                            </Grid>
                            </>
                                :
                            <Grid item xs={6}>
                                <br />
                                <Button variant="outlined" fullWidth onClick={this.updateProject}>Dar de alta</Button>
                            </Grid>
                            }
                        </Grid> </>
                    }
                </div>
        );
    }
}

export default detailProject;
