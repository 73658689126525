import React, { Component } from "react";
import ListRequests from "../../Requests/components/listRequests"
import { Grid, Card, Icon } from '@material-ui/core';
import CardHeader from '../../../global/Components/cardHeader';
import CalendarAbsences from "../../Requests/components/calendarAbsences"
import Vacations from "../../Home/Components/Vacations";


class VacationsResume extends Component {

    constructor(props) {
        const userSession = JSON.parse(localStorage.getItem('userSession'));
        super(props);
        this.state = {
            idUser: this.props.idUser ? this.props.idUser : userSession.idUser,
            reload: Math.random(),
            reloadVacations: Math.random(),
            vacationYear: new Date().getFullYear(),
        }
    }

    componentDidMount() { // Eventos que se lanzarán al cargar el componente

    }

    render() {
        const { reload, reloadVacations, vacationYear, idUser } = this.state;
        return (
            <Grid container spacing={2} id="down" className="vacationsresume">
                <Grid item xs={12} sm={6}>
                    <Card className="people-card">
                        <CardHeader title="Mis ausencias" icon="event" color="purple" />
                        <div className="people-card-body">
                            <ListRequests idRequestTypeParent="1" key={reload} idUser={idUser} />
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card className="people-card">
                        <Grid container className="people-card-header">
                            <Grid item xs={6}><Icon style={{ color: 'red', float: 'left', marginRight: '10px' }}>local_airport</Icon><div className="people-card-header-title">
                                {'Vacaciones ' + vacationYear}
                            </div>
                            </Grid>
                            <Grid item xs={6}>
                                <Icon className="float-right arrow" onClick={() => { this.setState({ vacationYear: this.state.vacationYear + 1, reloadVacations: Math.random() }) }}>arrow_right</Icon>
                                <Icon className="float-right arrow" onClick={() => { this.setState({ vacationYear: this.state.vacationYear - 1, reloadVacations: Math.random() }) }} >arrow_left</Icon>
                            </Grid>
                        </Grid>
                        <div className="people-card-body-min-padding">
                            <Vacations key={reloadVacations} year={vacationYear} idUser={idUser} />
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Card className="people-card">
                        <CardHeader title="Mi Calendario" icon="date_range" color="brown" />
                        <div className="people-card-body">
                            <CalendarAbsences numberOfMonths={3} key={reload} idUser={idUser} />
                        </div>
                    </Card>
                </Grid>
            </Grid>
        );
    }
}

export default VacationsResume;
