import React, { Component } from "react";
import { Grid, Icon } from '@material-ui/core';

class CardHeader extends Component {

    constructor(props) {
        super(props);
    }


    render() {
        return (
            <div>
                <Grid container className="people-card-header">
                    <Grid item xs={12}><Icon style={{ color: this.props.color, float: 'left', marginRight: '10px' }}>{this.props.icon}</Icon><div className="people-card-header-title">{this.props.title}</div></Grid>
                </Grid>
            </div>
        );
    }
}

export default CardHeader;
