import React, { Component } from "react";
import Http from "../../global/Actions/http"
import { IconButton, Icon, Container, Grid, Card } from '@material-ui/core';
import Moment from 'react-moment';
import CardHeader from '../../global/Components/cardHeader';
import TimeAction from "../TimeControl/Components/TimeAction"
import ListRequests from "../Requests/components/listRequests"
import Calendarabsences from "../Requests/components/calendarAbsences"
import Loading from '../../global/Components/loading';
import Vacations from "./Components/Vacations";

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            news: [], loadingNews: true,
            alerts: [], loadingAlerts: true,
            requests: [], loadingRequests: true,
            reload: Math.random(),
            vacationYear: new Date().getFullYear(),
            reloadVacations: Math.random()
        }
    }

    componentDidMount() {

        Http.Call("GET", "News/All").then(response => {
            if (!response.error) {
                this.setState({ news: response.data, loadingNews: false })
            }
        });

        Http.Call("GET", "Alerts/All").then(response => {
            if (!response.error) {
                this.setState({ alerts: response.data, loadingAlerts: false })
            }
        }); // Recupero las alertas desde la API

        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible') {
                this.setState({ reload: Math.random() });
            }
        });
    }

    render() {
        const { news, alerts, reload, vacationYear, reloadVacations } = this.state;
        const { loadingNews, loadingAlerts } = this.state;
        return (
            <Container maxWidth="xl">
                <Http/>
                <Grid container spacing={3} className="home">
                    <Grid item xs={12} sm={6} lg={4}>
                        <Card className="people-card">
                            <CardHeader title="Control horario" icon="schedule" color="#4667a9" />
                            <div className="people-card-body"><TimeAction key={ reload } /></div>
                            </Card>
                        </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Card className="people-card">
                            <CardHeader title="Últimas noticias" icon="feed" color="#fece0d" />
                            <div className="people-card-body">
                                {loadingNews ? <Loading /> : news.map(item =>
                                    <div key={"new-" + item.idNew } className="people-list"><a href={item.newLink} target="new"><strong>{item.newTitle} </strong><small>(<Moment format="DD/MM/yyyy">{item.newDate}</Moment>)</small><br />{item.newBody.substring(0, 110).replace("<p>", "") + '...'}</a></div>
                                )}
                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Card className="people-card">
                            <CardHeader title="Avisos importantes" icon="notifications_activa" color="#ec6e4c" />
                            <div className="people-card-body">
                                {loadingAlerts ? <Loading /> : alerts.map(item =>
                                    item.alertLink == "" ?
                                        <div key={"alert-" + item.idAlert} className="people-list"><strong>{item.alertTitle}</strong><br />{item.alertBody}</div>
                                    :
                                        <a key={"alert-" + item.idAlert} href={item.alertLink} target="new"><div className="people-list"><strong>{item.alertTitle}</strong><br />{item.alertBody}</div></a>
                                )}
                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Card className="people-card">
                            <CardHeader title="Mis solicitudes" icon="event" color="#ec6e4c" />
                            <div className="people-card-body">
                                {<ListRequests idRequestTypeParent={1} />}
                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Card className="people-card">
                            <CardHeader title="Mi Calendario" icon="date_range" color="#33a033" />
                            <div className="people-card-body">
                               <Calendarabsences numberOfMonths={1} />
                            </div>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Card className="people-card">
                            <Grid container className="people-card-header">
                                <Grid item xs={6}><Icon style={{ color: '#309ec6', float: 'left', marginRight: '10px' }}>local_airport</Icon><div className="people-card-header-title">
                                    {'Vacaciones ' + vacationYear}
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <Icon className="float-right arrow" onClick={() => { this.setState({ vacationYear: this.state.vacationYear + 1, reloadVacations: Math.random() }) } }>arrow_right</Icon>
                                    <Icon className="float-right arrow" onClick={() => { this.setState({ vacationYear: this.state.vacationYear - 1, reloadVacations: Math.random() }) }} >arrow_left</Icon>
                                    
                                </Grid>
                            </Grid>
                            <div className="people-card-body-min-padding">
                                <Vacations key={reloadVacations} year={vacationYear} />
                            </div>
                        </Card>
                    </Grid>
                </Grid>
                
            </Container>
        );
    }
}

export default Home;
