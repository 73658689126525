import React, { Fragment, useCallback, useMemo, useState, useEffect, useRef } from 'react'
import { Container, Grid, Card, Chip, Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@material-ui/core';
import CardHeader from '../../global/Components/cardHeader';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import moment from 'moment';
import Http from "../../global/Actions/http"
import { Tree, TreeNode } from 'react-organizational-chart';



export default function Organization() {

    const [departments, setDepartments] = useState([]);
    const [users, setUsers] = useState([]);
    const userSession = JSON.parse(localStorage.getItem('userSession'));

    useEffect(() => {
        Http.Call("GET", "Departments/All").then(response => {
            if (!response.error) {
                setDepartments(response.data);
            }
        });
        Http.Call("GET", "Users/All").then(response => {
            if (!response.error) {
                setUsers(response.data);
            }
        });
    }, []);

    const Card = (props) => {
        return (
            <Fragment>
                {
                    props.udep.map(d => (
                        //<Fragment>
                          //  {users.filter(u => u.idDepartment == d.idDepartment).length > 0 &&
                                <Fragment>
                                    {
                                        departments.filter(ud => ud.idDepartmentParent == d.idDepartment).length > 0 ?
                                            <TreeNode label={<Department idDepartment={d.idDepartment} />}>
                                                <Card udep={departments.filter(ud => ud.idDepartmentParent == d.idDepartment)} />
                                            </TreeNode>
                                            :
                                            <TreeNode label={<Department idDepartment={d.idDepartment} />} />
                                    }
                                </Fragment>
                            //}
                        //</Fragment>
                ))
                }
            </Fragment>)
    }

    const Department = (props) => {
        var department = departments.filter(d => d.idDepartment == props.idDepartment)[0]
        var manager = users.filter(u => u.idUser == department.idUserManager)[0];
        var dusers = users.filter(u => u.idDepartment == props.idDepartment)
        dusers = dusers.filter(u => u.userActive == true);
        
        return (
            <div style={{ border: '1px solid lightgray', padding: '15px 8px' }}>
                <strong style={{ color:'#4566a8' }}>{department.departmentName}</strong><br /><br />
                <small><strong style={{ color:'#0a8cbd'}}>{manager.userName + ' ' + manager.userSurname}</strong></small><br /><br />
                {
                    dusers.map(u => (
                        <small>{u.userName + ' ' + u.userSurname}<br /></small>
                    ))
                }
            </div>)
    }

    return (
        <Container className="req" maxWidth="xl">
            <Grid id="header" container spacing={5} padding={10}>
                <Grid item xs={12}><h2>Organigrama</h2></Grid>
            </Grid>
            <div>
                {users.length > 0 && departments.length > 0 && 
                <Tree>
                    <Card udep={departments.filter(ud => ud.idDepartmentParent == 0)} />
                </Tree>
                }
            </div>
        </Container>
    )
}
