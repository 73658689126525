import React, { useState, useEffect } from "react";
import DynamicTable from "../../global/Components/dynamicTable";
import { Container, Button, Grid, Card, Icon, Tooltip, Chip } from '@material-ui/core';
import CardHeader from '../../global/Components/cardHeader';
import Modal from '../../global/Components/modal';
import Moment from 'react-moment';
import { useParams } from "react-router-dom";
import DetailRequest from "../Requests/components/detailRequest";

function History() {

    const userSession = JSON.parse(localStorage.getItem('userSession'));
    const endpoint = useParams().filter;
    const [reload, setReload] = useState(0);
    const [key, setKey] = useState(Math.random());
    const [modal, setModal] = useState({
        open: false,
        title: "",
        icon: "",
        color: "",
        component: "",
        size: "",
        key: Math.random()
    });

    //Definición de columnas
    const columns = [
        { accessor: "idRequest", Header: "Nº", width: "5%" },
        { accessor: "requestLastAction", width: "10%", Header: "Fecha ult. acción", Cell: props => <Moment format="DD/MM/YYYY HH:mm" utc local>{props.value}</Moment> },
        { accessor: "userFullname", width: "23%", Header: "Empleado" },
        { accessor: "userOffice", width: "23%", Header: "Ubicación" },
        { accessor: "requestTypeParentName", width: "10%", Header: "Categoría" },
        { accessor: "requestTypeName", width: "10%", Header: "Detalle" },
        { accessor: "idRequestStatus", width: "10%", Header: "Estado", Cell: props => <div><Chip label={props.value == 1 ? "APROBADA" : props.value == 2 ? "DENEGADA" : "PENDIENTE"} className={props.value == 1 ? "gLight" : props.value == 2 ? "rLight" : "yLight"} /></div> },
        {
            Header: "Acciones", filterable: false, width: "9%", Cell: props =>
                <div>
                    <Tooltip title="Ver detalles">
                        {props.row.original.idRequestType == "Ausencia" ?
                            <Button variant="outlined" onClick={() => { setModal({ open: true, height: "80vh", title: "Ver detalle de solicitud", icon: "manage_accounts", color: "blue", size: "sm", component: <DetailRequest setReload={() => setReload(Math.random())} idRequest={props.row.original.idRequest} />, key: Math.random() }) }}><Icon>more_horiz</Icon></Button>
                            :
                            props.row.original.idRequestType == "Solicitud" ?
                                <Button variant="outlined" onClick={() => { setModal({ open: true, height: "80vh", title: "Ver detalle de solicitud", icon: "manage_accounts", color: "blue", size: "sm", component: <DetailRequest setReload={() => setReload(Math.random())} idRequest={props.row.original.idRequest} />, key: Math.random() }) }}><Icon>more_horiz</Icon></Button>
                                :
                                <Button variant="outlined" onClick={() => { setModal({ open: true, height: "80vh", title: "Ver detalle de solicitud", icon: "manage_accounts", color: "blue", size: "sm", component: <DetailRequest setReload={() => setReload(Math.random())} idRequest={props.row.original.idRequest} />, key: Math.random() }) }}><Icon>more_horiz</Icon></Button>
                        }
                    </Tooltip>
                </div>
        }
    ];

    const tableOrder = [
        {
            orderField: "requestLastAction",
            orderAsc: false
        }
    ];


    return (
        <Container className="history" maxWidth="xl">
            {/* ---------------- CABECERA -------------------- */}
            <Grid id="header" container spacing={5} padding={10}>
                <Grid item xs={12}><h2>Historial</h2></Grid>
            </Grid>
            {/* ---------------- FIN CABECERA -------------------- */}

            <Card className="people-card">
                <CardHeader title="Historial de solicitudes y subida de ficheros" icon="history" color="red" />
                <DynamicTable customOrder={tableOrder} key={key} reload={reload} isGlobalSearch={true} isOrdered={true} isColumnsSearch={true} columns={columns} endpoint={"Requests/History" + endpoint} />
                <Modal key={modal.key} height={modal.height} open={modal.open} title={modal.title} icon={modal.icon} color={modal.color} component={modal.component} size={modal.size} />
            </Card>
        </Container>
    )
}

export default History;