import React, { Component } from "react";
import Http from "../../global/Actions/http"
import { Card, Grid, Container, Button, Link, TextField, Icon } from '@material-ui/core';
import Loading from '../../global/Components/loading';
import CardHeader from '../../global/Components/cardHeader';
import Modal from '../../global/Components/modal';
import DetailNew from "../News/components/detailNew";

class News extends Component {

    constructor(props) {
        const userSession = JSON.parse(localStorage.getItem('userSession')); // Constante objeto con todos los datos del usuario que ha hecho login en la aplicación a partir de cookie

        super(props);
        this.state = {
            news: [], //array donde guardo las noticias
            loading: true,
            newNoticia: { // Objeto nueva solicitud de noticia
                "newTitle": "", // Tipo de noticia
                "newBody": "", //Contenido de la nueva noticia
                "newLink": "", //url opcional, normalmente es un campo vacío
                "newDate": "", // Fecha
                "idUser": userSession.idUser, // Id del usuario a solicitar creacion de noticia
            },
            modal: {
                open: false,
                title: "",
                icon: "",
                color: "",
                component: "",
                size: "",
                key: Math.random()
            },
            reload: Math.random()
        };

        this.handleNewRequest = this.handleNewRequest.bind(this);
        this.postNewRequest = this.postNewRequest.bind(this);
        this.getNews = this.getNews.bind(this);
    }

    componentDidMount() { // Eventos que se lanzarán al cargar el componente
        this.getNews();
    }

    getNews() {
        Http.Call("GET", "News/All").then(response => { // Captura de noticias
            if (!response.error) {
                this.setState({ news: response.data, loading: false }); // Las trasladamos al state
            }
        });
    }

    handleNewRequest(e) { // Handle para controlar en state los valores de los elementos de nueva solicitud
        e.persist(); // Mantengo persistente el estado del elemento hasta el final del controlador
        var value = e.target.value; // Vuelco en la variable value el valor de elemento
        this.setState(prevState => ({ newNoticia: { ...prevState.newNoticia, [e.target.name]: value } })); // Establezco en el state el valor del campo actual respetando el resto de elementos
    }

    postNewRequest() { // Mandamos la solicitud de nueva petición de alerta a la API
        Http.Call("POST", "News/New", this.state.newNoticia).then(response => { //Realizo la llamada POST
            if (!response.error) {
                this.setState({ newNoticia: { "newTitle": "", "newBody": "", "newLink": "", "newDate": "" } }); // Reseteo los valores del state y en consecuencia del formulario
                this.getNews();// Reseteo la key reload para que recargue el componente de lista
            }
        });
    }

    deleteNew(id) {//Borramos desde API y luego tambien en la lista a partir del id de la noticia

        Http.Call("DELETE", "News/Delete/" + id).then(response => {
            if (!response.error) { //Compruebo si el servicio web me confirma que se ha eliminado la noticia correctamente
                this.getNews(); //reseteo la key reload para recargar el componente de lista
            }
        });
    }

    render() {

        const { news, loading, newNoticia, reload, modal } = this.state;

        return (

            <Container className="news" maxWidth="xl">
                <Http />
                {/* ---------------- CABECERA -------------------- */}
                <Grid id="header" container spacing={5} padding={10}>
                    <Grid item xs={12}><h2>Noticias</h2></Grid>
                </Grid>
                {/* ---------------- FIN CABECERA -------------------- */}

                <Card className="people-card">
                    <CardHeader title="Nueva Noticia" icon="manage_accounts" color="red" />
                    {loading ? <Loading /> :
                        <div className="people-card-body">
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={4}>
                                    <span>Titulo de la Noticia:</span><br />
                                    <TextField fullWidth type="text" id="newTitle" name="newTitle" value={newNoticia.newTitle} onChange={this.handleNewRequest} />
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <span>Descripción:</span><br />
                                    <TextField fullWidth multiline variant="filled" type="text" id="newBody" name="newBody" value={newNoticia.newBody} onChange={this.handleNewRequest} />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <span>Enlace (opcional):</span><br />
                                    <TextField fullWidth type="text" id="newLink" name="newLink" value={newNoticia.newLink} onChange={this.handleNewRequest} />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <span>Fecha:</span><br />
                                    <TextField fullWidth type="date" name="newDate" id="newDate" name="newDate" value={newNoticia.newDate} onChange={this.handleNewRequest} />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Button variant="contained" color="primary" component={Link} onClick={this.postNewRequest}>Crear Nueva Noticia</Button>
                                </Grid>
                            </Grid>
                        </div>
                    }
                </Card>

                <Card className="people-card" key={reload}>
                    <CardHeader title="Registro de Noticias" icon="article" color="blue" />
                    <div className="people-card-body">
                        {loading ? <Loading /> : news.map(news =>
                            <div className="people-list" key={"new-" + news.idNew}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={8}>
                                        <strong>Título: </strong> {news.newTitle}<br />
                                        <strong>Contenido: </strong> {news.newBody}<br />
                                        <strong>Enlace: </strong> {news.newLink == "" ? "Sin enlace" : news.newLink}
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Button component={Link} variant="contained" className="newButton" onClick={() => { this.deleteNew(news.idNew) }}>Borrar Noticia</Button>
                                        <Button component={Link} variant="contained" className="newButton" onClick={() => { this.setState({ modal: { open: true, title: "Ver detalle de noticia", icon: "manage_accounts", color: "blue", size: "sm", component: <DetailNew updateParent={this.getNews} idNew={news.idNew} />, key: Math.random() } }) }}><Icon>manage_accounts</Icon>Editar Noticia</Button>
                                    </Grid>
                                    
                                </Grid>
                            </div>
                        )}
                    </div>
                </Card>
                <Modal key={modal.key} open={modal.open} title={modal.title} icon={modal.icon} color={modal.color} component={modal.component} size={modal.size}/>
            </Container >

        );
    }
}

export default News;