import React, { Fragment, useEffect, useState, useRef } from "react";
import { useTable, usePagination, useExpanded, useSortBy } from "react-table";
import { IconButton, Grid, Table, TableBody, TableHead, TableRow, TableCell, Button } from '@material-ui/core';
import Http from "../../global/Actions/http"
import { DebounceInput } from 'react-debounce-input';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';

const DynamicTable = ({
    columns,
    isGlobalSearch = false,
    isColumnsSearch = false,
    isOrdered = false,
    isPaginated = true,
    customFilter = [],
    customOrder = [],
    customPageSize = 10,
    endpoint,
    reload = 0,
    ...props
}) => {

    const [isMounted, setIsMounted] = useState(true);
    const [data, setData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false);
    const [controlledPageCount, setControlledPageCount] = useState(0);
    const [globalSearch, setGlobalSearch] = useState("");
    const [prevGlobalSearch, setPrevGlobalSearch] = useState("");
    const [prevColumnSearch, setPrevColumnSearch] = useState(customFilter);
    const [columnSearch, setColumnSearch] = useState(customFilter);
    const [order, setOrder] = useState(customOrder);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        setHiddenColumns,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: customPageSize,
                hiddenColumns: columns
                    .filter((column) => !column.show)
                    .map((column) => column.id),
            },
            manualPagination: true,
            manualSortBy: false,
            autoResetPage: false,
            pageCount: controlledPageCount,
        },
        useSortBy,
        useExpanded,
        usePagination
    );

    const fetchData = ({ pageSize, pageIndex, globalSearch, columnSearch, order, endpoint }) => {
        setLoading(true);
        if (prevGlobalSearch != globalSearch) {
            gotoPage(0);
            setPrevGlobalSearch(globalSearch);
        }
        if (prevColumnSearch != columnSearch) {
            gotoPage(0);
            setPrevColumnSearch(columnSearch);
        }
        Http.Call("post", endpoint, { pageSize: pageSize, pageNumber: pageIndex, globalSearch: globalSearch, search: columnSearch, order: order }).then(response => {
            if (!response.error) {
                setData(response.data);
                setTotalRecords(response.pagination.records);
                setControlledPageCount(response.pagination.pages);
                setLoading(false)
            }
        });
    }

    const handleGlobalSearch = (search) => {
        setGlobalSearch(search);
    }

    const handleColumnSearch = (event) => {
            var searchField = event.target.name;
            var searchValue = event.target.value;
            var searchArray = columnSearch;
            searchArray = searchArray.filter(filter => filter.SearchField !== searchField)
            if (searchValue) {

                searchArray.push({
                    SearchField: searchField,
                    SearchValue: searchValue,
                    SearchMode: 'Contains'
                });
            }
            setColumnSearch(searchArray);
    }

    const handleOrder = (orderField, orderMode) => {
        var orderArray = order;
        orderArray = orderArray.filter(filter => filter.orderField !== orderField)
        if (orderMode) {
            orderArray.unshift({
                orderField: orderField,
                orderAsc: orderMode=="asc" ? true : false
            });
        }
        setOrder(orderArray);
    }

    useEffect(() => {
        if (isMounted) {
            fetchData && fetchData({ pageIndex, pageSize, globalSearch, columnSearch, order, endpoint });
        }
    }, [pageIndex, pageSize, globalSearch, columnSearch, order, endpoint, reload]);

    useEffect(() => {
        return () => {
            setIsMounted(false);
        }
    }, []);

    return (
        <Fragment>
            {isGlobalSearch &&
                <Grid container >
                    <Grid item xs={6}>
                    </Grid>
                    <Grid item xs={6}>
                        <DebounceInput
                            style={{ float: 'right', border: '1px solid #d7d7d7', padding: '10px', marginTop: '15px', marginBottom: '10px', marginRight: '20px', width: '200px' }}
                            minLength={0}
                            debounceTimeout={500}
                            id="searchTerm"
                            name="searchTerm"
                            type="text"
                            onChange={(e) => handleGlobalSearch(e.target.value)}
                            placeholder="Búsqueda global"
                            autoComplete="|"
                        />
                    </Grid>
                </Grid>
            }
            <div style={{ overflowX: 'auto' }}>
            <Table className="dynamicTable" {...getTableProps()} >
                <TableHead>
                    {headerGroups.map((headerGroup, i) => (
                        <TableRow key={"hg-" + i} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column,i) => (
                                <TableCell key={"hc-" + i} {...column.getHeaderProps()} style={{ width: column.width }}>
                                    <div>
                                        <nobr>{column.render("Header")}

                                            {isOrdered &&
                                            <>
                                                {
                                                order.filter(filter => filter.orderField === column.id).length > 0 ?
                                                    order.filter(filter => filter.orderField === column.id)[0].orderAsc ?
                                                        <IconButton aria-label="delete" size="small" onClick={() => handleOrder(column.id, "desc")}>
                                                            <KeyboardArrowDownIcon fontSize="inherit" />
                                                        </IconButton>
                                                        :
                                                        <IconButton aria-label="delete" size="small" onClick={() => handleOrder(column.id, null)}>
                                                            <KeyboardArrowUp fontSize="inherit" />
                                                        </IconButton>
                                                    :
                                                    <IconButton aria-label="delete" size="small" onClick={() => handleOrder(column.id, "asc")}>
                                                        <UnfoldMoreIcon fontSize="inherit" />
                                                    </IconButton>
                                            }</>}
                                            
                                        </nobr>
                                    </div>
                                </TableCell>
                            ))}

                        </TableRow>
                    ))}

                </TableHead>
                {isColumnsSearch &&
                    <TableBody>
                        {headerGroups.map((headerGroup,c) => (
                            <TableRow key={"hgx-" + c}>
                                {headerGroup.headers.map((column, i) => (
                                    <TableCell {...column.getHeaderProps()} style={{ width: column.width }}>
                                        { column.filterable == null &&
                                        <DebounceInput
                                            key={"dbi-" + i}
                                            name={column.id}
                                            style={{ border: '1px solid #d7d7d7', padding: '10px', marginBottom: '4px', width: '95%' }}
                                            minLength={0}
                                            debounceTimeout={500}
                                            placeholder="Búscar"
                                            onChange={(e) => handleColumnSearch(e)}
                                            />
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                }
                <TableBody {...getTableBodyProps()} >

                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <TableRow key={"tr-" + i} style={{ filter: loading ? 'blur(2px)' : '', height: '50px' }} {...row.getRowProps()}>
                                {row.cells.map((cell,x) => {
                                    return (
                                        <TableCell
                                            key={"tc-"+x}
                                            {...cell.getCellProps()}
                                            style={{ width: cell.column.width }}>
                                            {cell.render("Cell")}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}

                </TableBody>
                </Table>
            </div>
            {Boolean(isPaginated) && (
                <Grid container style={{ padding: 20 }} >
                    <Grid item xs={3}>
                        {totalRecords} resultados<br />
                        <small>(Mostrando del {(pageIndex * pageSize + 1)} al {(pageIndex * pageSize + pageSize)})</small>
                    </Grid>
                    <Grid item xs={9}>
                        <Button variant="outlined" className="float-right" color="primary" onClick={() => nextPage()} disabled={!canNextPage} style={{ marginLeft: '20px' }}>Siguiente</Button>
                        <div className="float-right" style={{ margin: '8px'}}>Página {pageIndex + 1} de {pageOptions.length}</div>
                        <Button variant="outlined" className="float-right" color="primary" onClick={() => previousPage()} disabled={!canPreviousPage} style={{ marginRight: '20px' }}>Anterior</Button>
                    </Grid>
                </Grid>
            )}
        </Fragment>
    );
};

export default DynamicTable;