import React, { Component } from "react";
import Http from "../../../global/Actions/http"
import DayPicker from 'react-day-picker';
import { Grid } from '@material-ui/core';
import 'react-day-picker/lib/style.css';
import Loading from '../../../global/Components/loading';

class Calendarabsences extends Component {

    constructor(props) {
        super(props);
        const userSession = JSON.parse(localStorage.getItem('userSession'));
        this.state = {
            idUser: this.props.idUser ? this.props.idUser : userSession.idUser,
            myAbsences: [],  // Ausencias guardadas
            absencesDays: [], // Días a marcar en el calendario
            pendingDays: [], // Días a marcar en el calendario
            nonWorkingDays: [], // Días no laborables
            vacationDays: [], //Días de vacaciones
            numberOfMonths: this.props.numberOfMonths,
            loading: true,
            idCalendar: userSession.idCalendar
            }
    }

    componentDidMount() { // Eventos que se lanzarán al cargar el componente
        Http.Call("POST", "Requests/List/", { IdRequestTypeParent: 1, IdUser: this.state.idUser }).then(response => { // Captura de ausencias guardadas del usuario en curso
            if (!response.error) {
                this.setState({ myAbsences: response.data }); // Las trasladamos al state
                var absencesDays = [] // Creamos el array que marca como seleccionados los días en el calendario del componente react-day-picker (ver documentacion para formato)
                var vacationDays = [] // Creamos el array que marca como seleccionados los días en el calendario del componente react-day-picker (ver documentacion para formato)
                var pendingDays = [] // Creamos el array que marca como seleccionados los días en el calendario del componente react-day-picker (ver documentacion para formato)
                this.state.myAbsences.map(absence => { // Recorremos el objeto myAbsences para rellenar el array con rangos desde-hasta
                    var after = new Date(absence.requestFrom); // Creamos objeto javascript a partir de la fecha desde
                    var before = new Date(absence.requestTo); // Creamos objeto javascript a partir de la fecha hasta
                    after.setDate(after.getDate() - 1); // Al ser un rango, necesitamos restar 1 dia a la fecha desde.
                    before.setDate(before.getDate() + 1); // Al ser un rango, necesitamos sumar 1 dia a la fecha hasta.
                    var range = { "after": after, "before": before } // Creamos objeto a insertar en arraytras
                    if (absence.idRequestStatus == 1) {
                        if (absence.idRequestType == 11) { vacationDays.push(range) } //Vacaciones
                        else { absencesDays.push(range) } //Otras ausencias
                    } // Lo insertamos en el array de confirmadas
                    if (absence.idRequestStatus == 3) { pendingDays.push(range) } // Lo insertamos en el array de pendientes
                })
                this.setState({ absencesDays: absencesDays, pendingDays: pendingDays, vacationDays: vacationDays, loading: false }) // Trasladamos el array al state
            }
        });

        Http.Call("GET", "Calendars/getDatesByUser/" + this.state.idUser).then(response => {
            if (!response.error) {
                if (response.data.length > 0) {
                    var datesarray = [];
                    response.data.map(date => {
                        datesarray.push(new Date(date.calendarDate));
                    });
                    this.setState({ nonWorkingDays: datesarray });
                }
            }
        });
    }

    render() {
        const { numberOfMonths, absencesDays, pendingDays, loading, nonWorkingDays, vacationDays } = this.state; // Creo constantes de objetos a partir del state del componente
        const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
        const dlong = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
        const dshort = ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'];
        const modifiers = {
            pendingDays: pendingDays,
            absencesDays: absencesDays,
            nonworkingdays: nonWorkingDays,
            vacationDays: vacationDays
        };
        const modifiersStyles = {
            absencesDays: {
                color: 'white',
                backgroundColor: '#8bc18f',
            },
            vacationDays: {
                color: 'white',
                backgroundColor: '#33a033',
            },
            pendingDays: {
                color: 'black',
                backgroundColor: 'lightgoldenrodyellow',
            },
            nonworkingdays: {
                color: 'white',
                backgroundColor: 'darkred',
                border: '1px solid white',
            }
        }
        return (
            loading ? <Loading /> :
                <div>
                    <DayPicker pagedNavigation modifiers={modifiers} modifiersStyles={modifiersStyles} numberOfMonths={numberOfMonths} months={months} weekdaysLong={dlong} weekdaysShort={dshort} firstDayOfWeek={1} />
                    <Grid container className="leyend">
                        <Grid item xs={4}>
                            <div className="littlebox" style={{ 'backgroundColor': modifiersStyles.vacationDays.backgroundColor }} />Vacaciones
                        </Grid>
                        <Grid item xs={4}>
                            <div className="littlebox" style={{ 'backgroundColor': modifiersStyles.absencesDays.backgroundColor }} />Otras ausencias
                        </Grid>
                        <Grid item xs={4}>
                            <div className="littlebox" style={{ 'backgroundColor': modifiersStyles.pendingDays.backgroundColor }} />Pte. Confirmación
                        </Grid>
                    </Grid>
                </div>
        );
    }
}

export default Calendarabsences;
