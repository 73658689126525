import React, { Component } from "react";
import Http from "../../global/Actions/http"
import { Container, Grid, TextField, Link, Button, NativeSelect } from '@material-ui/core';
import jwt_decode from "jwt-decode";

import logo from '../../assets/images/logo_people.png';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            login: {
                loginEmail: "",
                loginPassword: ""
            },
            newPass: {
                userEmail: "",
                userRecoveryHash: "",
                userPassword: ""
            },
            step: 0
        }
        this.handleLogin = this.handleLogin.bind(this);
        this.postLogin = this.postLogin.bind(this);
        this.getRecoveryHash = this.getRecoveryHash.bind(this);
        this.handleNewPassword = this.handleNewPassword.bind(this);
        this.saveNewPassword = this.saveNewPassword.bind(this);
    }

    componentDidMount() {
        localStorage.clear();
        localStorage.setItem('userSession', null)
        localStorage.setItem('loginToken', null)
        localStorage.setItem('idTenant', null)
    }


    handleNewPassword(e) {
        e.persist();
        var value = e.target.value;
        this.setState(prevState => ({ newPass: { ...prevState.newPass, [e.target.name]: value, userEmail: this.state.login.loginEmail } }));
    }

    handleLogin(e) {
        e.persist(); 
        var value = e.target.value; 
        this.setState(prevState => ({ login: { ...prevState.login, [e.target.name]: value }}));
    }

    getRecoveryHash() {
        Http.Call("POST", "Login/LostMyPassword", { userEmail: this.state.login.loginEmail}).then(response => { //Realizo la llamada POST
            if (!response.error) {
                this.setState({ step: 2 });
            }
        })
    }

    saveNewPassword() {
        Http.Call("POST", "Login/SetNewPassword", this.state.newPass).then(response => { //Realizo la llamada POST
            if (!response.error) {
                this.setState({ step: 0 });
            }
        })
    }

    postLogin() { // Mandamos la solicitud de autentificación a la API
        Http.Call("POST", "Login/SignIn", this.state.login).then(response => { //Realizo la llamada POST
            if (!response.error) {
                localStorage.setItem('userSession', JSON.stringify(response.data.userSession))
                localStorage.setItem('loginToken', JSON.stringify(response.data.loginToken))
                
                const tokenparsed = jwt_decode(response.data.loginToken.token);
                localStorage.setItem('idTenant', tokenparsed.IdTenant);
                this.props.history.push('/home');
            }
        })
        
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.postLogin();
        }
    }

    render() {
        const { login, step, newPass } = this.state;
        return (
            <Container maxWidth="xl" className="login">
                <Http />
                <Grid container>
                    <Grid item xs={12} md={4}></Grid>
                    <Grid item xs={12} md={4} className="logincol">
                        <Grid container>
                            <Grid item md={1} />
                            <Grid item xs={12} md={10} className="logincol">
                                
                                <div className="loginform">
                                <img src={logo} className="loginlogo" />
                                <h2>Portal del empleado</h2>
                                    <br />
                                    {step == 0 &&
                                        <div>
                                            <span>E-Mail</span><br />
                                        <TextField id="loginEmail" name="loginEmail" fullWidth value={login.loginEmail} onChange={this.handleLogin} onKeyPress={this.handleKeyPress}/><br /><br />
                                            <span>Contraseña</span><br />
                                        <TextField id="loginPassword" name="loginPassword" type="password" fullWidth value={login.loginPassword} onKeyPress={this.handleKeyPress} onChange={this.handleLogin} /><br /><br />
                                            <Button type="submit" variant="contained" color="primary" fullWidth onClick={this.postLogin} >ACCEDER</Button>
                                            <br /><br />¿No puedes acceder? <Link onClick={() => this.setState({ step: 1 })}>Haz click aqui</Link>
                                        </div>
                                    }
                                     { step == 1 &&
                                        <div>
                                        Podemos enviarte un código de recuperación a tu e-mail, para ello introduce tu e-mail y pulsa obtener código.<br /><br /><br />
                                            <span>E-Mail</span><br />
                                            <TextField id="loginEmail" name="loginEmail" fullWidth value={login.loginEmail} onChange={this.handleLogin} /><br /><br />
                                            <Button variant="contained" color="primary" fullWidth onClick={this.getRecoveryHash} >OBTENER CÓDIGO</Button>
                                            <br /><br /><Link onClick={() => this.setState({ step: 0 })}>Volver atrás</Link>
                                        </div>
                                    }
                                    {step == 2 &&
                                        <div>
                                            <span>Código de recuperación</span><br />
                                        <TextField id="userRecoveryHash" name="userRecoveryHash" fullWidth value={newPass.userRecoveryHash} onChange={this.handleNewPassword} /><br /><br />
                                            <span>Nueva Contraseña</span><br />
                                        <TextField type="password" id="userPassword" name="userPassword" fullWidth value={newPass.userPassword} onChange={this.handleNewPassword} /><br /><br /><br />
                                        <Button variant="contained" color="primary" fullWidth onClick={this.saveNewPassword} >CAMBIAR CONTRASEÑA</Button>
                                        <br /><br /><Link onClick={() => this.setState({ step: 0 })}>Cancelar cambio de contraseña</Link>
                                        </div>
                                    }
                                </div>
                            </Grid>
                            <Grid item md={1} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}></Grid>
                </Grid>
            </Container>
        );
    }
}

export default Login;
