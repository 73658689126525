import React, { Component } from "react";
import Http from "../../../global/Actions/http"
import { Container, Grid, Card } from '@material-ui/core';
import { FileIcon, defaultStyles } from 'react-file-icon';
import CardHeader from '../../../global/Components/cardHeader';

class DraftDocuments extends Component {

    constructor(props) {
        super(props);
        this.state = {
            docs: []
        }
        this.downloadFile = this.downloadFile.bind(this);
    }

    componentDidMount() {
        Http.Call("GET", "Company/Docs").then(response => {
            if (!response.error) {
                this.setState({ docs: response.data });
            }
            
        });
    }

    downloadFile(folder, filename) {
        Http.Call("GET", "Company/DownloadFile/"+folder+"/"+filename).then(response => { //Realizo la llamada GET
            if (!response.error) {
                const linkSource = 'data:application/pdf;base64,' + response.data.fileContent;
                const downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                downloadLink.download = response.data.fileName;
                downloadLink.click();
            }
        })
    }

    render() {
        const { docs } = this.state;
        return (
            <div>
                {
                    docs.map(folder => (
                        <>
                            {folder.folderName === "Plantillas" &&
                                    <div>
                                        {
                                            folder.files.length == 0 ? <center>No hay documentos disponibles</center> :
                                                folder.files.map((file, i) =>
                                                    <div key={"itemreq-" + i} className="people-list clickable" onClick={() => { this.downloadFile(folder.folderName, file.fileName) }}>
                                                        <Grid container spacing={1} padding={3}>
                                                            <Grid item className="fileicon" xs={2}>
                                                                <FileIcon extension={file.fileExt} {...defaultStyles[file.fileExt]} />
                                                            </Grid>
                                                            <Grid item xs={10}>
                                                                <div style={{ marginTop: "5px" }}>
                                                                    <strong>{file.fileName}</strong><br />
                                                                    <div>Descargar</div>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                )
                                        }
                                    </div>
                            }
                        </>
                    ))
                }
            </div>
        );
    }
    
}

export default DraftDocuments;
