import React, { Component } from "react";
import Candidato from '../../assets/images/generic.png';
import Http from "../../global/Actions/http"
import { Container, Card, CardActionArea, CardContent, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid';
import Loading from '../../global/Components/loading';

class Personal extends Component { //Clase Personal
    constructor(props) { //propiedades que vamos a usar
        super(props)
        this.state = {
            users: [], //informacion de los empleados
            loading: true
        }
    }

    componentDidMount() { //didmount para la app
        Http.Call("GET", "Users/All").then(response => {
            if (!response.error) {
                var result = response.data;
                result.sort((a, b) => a.idDepartment - b.idDepartment);
                result.sort((a, b) => a.departmentOrder - b.departmentOrder);
                var users = result.filter(function (x) {
                    return x.userActive == true
                });
                this.setState({ users: users, loading: false });
            }
        }); //Llamada a la API recuperar personal
    }

    render() {
        //imagen generica en base64 para aquellos que no tienen fotografia
        const { loading, users } = this.state;
        return (
            <Container className="req"  maxWidth="xl">
                <Http />
                {/* ---------------- CABECERA -------------------- */}
                <Grid id="header" container spacing={5} padding={10}>
                    <Grid item xs={12}><h2>Directorio de empleados</h2></Grid>
                </Grid>
                <div className="employeeBody">
                {loading ? <Loading /> :
                    <div>
                        <Grid container spacing={2}>
                            {
                                users.map((user, i) => (//map para los empleados, con foto, nombre,apellido e email
                                    <React.Fragment key={"dpment-" + i}>
                                        {user.departmentVisible == true ?
                                       <React.Fragment key={"user-" + i}>
                                            {user.idDepartment != users[i == 0 ? 0 : i - 1].idDepartment || i == 0 ? <div className="departamento"><h2><strong>{user.departmentName}</strong></h2></div> : ""}
                                                <Grid item xs={6} md={3}>
                                                <Card className="tarjetaPersonal">
                                                    <CardActionArea>
                                                        <img src={user.userImage ? "data:image/jpg;base64," + user.userImage : Candidato} className="personalImage"></img>
                                                        <CardContent>
                                                                <Typography><strong className="letraPersonal">{user.userName} {user.userSurname}</strong><br /></Typography>
                                                                <Typography><span className="letraPersonal"><a href={"mailto:" + user.userEmail}>{user.userEmail}</a></span></Typography>
                                                                <hr/>
                                                                <Typography><strong className="letraPersonal">{user.userJobName}</strong></Typography>
                                                                
                                                        </CardContent>
                                                    </CardActionArea>
                                                </Card>
                                            </Grid>
                                        </React.Fragment> : <React.Fragment></React.Fragment>
                                        } </React.Fragment>
                                ))}
                        </Grid>
                    </div>
                }
                </div>
            </Container>
        )
    }
}

export default Personal;