import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "./sections/PrivateRoute";
import Home from './sections/Home'
import MyCompany from './sections/MyCompany'
import Login from './sections/Login'
import AbsencesTeam from './sections/AbsencesTeam';
import Requests from './sections/Requests';
import Approvals from './sections/Approvals';
import Personal from './sections/Personal';
import Profile from './sections/Profile';
import ListUsers from './sections/ListUsers';
import TimeControl from './sections/TimeControl';
import Alerts from './sections/Alerts';
import History from './sections/History';
import Help from './sections/Help';
import News from './sections/News';
import WorkCalendar from './sections/WorkCalendar';
import Departments from './sections/Departments';
import Workplaces from './sections/Workplaces';
import WorkTime from './sections/WorkTime';
import Projects from './sections/Projects';
import Company from './sections/Company';
import Organization from './sections/Organization';
import Reports from './sections/Reports';
import './App.css';

const App = () => { 
    return (
            <Router>
                <Switch>     
                    <Route exact path="/" component={Login} />
                    <PrivateRoute exact path="/Home" component={Home} />
                    <PrivateRoute exact path="/Mycompany" component={MyCompany} />
                    <PrivateRoute exact path="/Profile" component={Profile} />
                    <PrivateRoute exact path="/Profile/:idUser" component={Profile} />
                    <PrivateRoute exact path="/ListUsers/:filter" component={ListUsers} />
                    <PrivateRoute exact path="/Alerts" component={Alerts} />
                    <PrivateRoute exact path="/Approvals" component={Approvals} />
                    <PrivateRoute exact path="/AbsencesTeam" component={AbsencesTeam} />
                    <PrivateRoute exact path="/Personal" component={Personal} />
                    <PrivateRoute exact path="/Requests/:idRequestTypeParent" component={Requests} />
                    <PrivateRoute exact path="/WorkTime" component={WorkTime} />
                    <PrivateRoute exact path="/Admintime" component={TimeControl} />
                    <PrivateRoute exact path="/History/:filter" component={History} />
                    <PrivateRoute exact path="/Help" component={Help} />
                    <PrivateRoute exact path="/News" component={News} />
                    <PrivateRoute exact path="/WorkCalendar" component={WorkCalendar} />
                    <PrivateRoute exact path="/Departments" component={Departments} />
                    <PrivateRoute exact path="/Workplaces" component={Workplaces} />
                    <PrivateRoute exact path="/Projects" component={Projects} />
                    <PrivateRoute exact path="/Company" component={Company} />
                <PrivateRoute exact path="/Organization" component={Organization} />
                <PrivateRoute exact path="/Reports" component={Reports} />
                </Switch>  
            </Router>
    );
};

export default App;

