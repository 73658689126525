import React, { Component } from "react";
import { Dialog, Icon, IconButton, Card }  from '@material-ui/core';
import CardHeader from './cardHeader';

class Modal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open ? this.props.open : false,
            title: this.props.title ? this.props.title : "Información",
            icon: this.props.icon ? this.props.icon : "lightbulb", // fonts.google.com/icons
            color: this.props.color ? this.props.color : "gray", // Colores html o hex
            size: this.props.size ? this.props.size : "sm", // xs, sm, md
            height: this.props.height ? this.props.height : "65vh" // xs, sm, md
        }
    }


    render() {
        const { open, title, icon, color, size, height } = this.state;
        const { component } = this.props;
        return (
            <div>
                <Dialog
                    open={open}
                    fullWidth
                    maxWidth={size}
                    onClose={() => { this.setState({ open: false }) }}
                >
                    <Card style={{ width: "100%" }}>
                        <CardHeader title={title} icon={icon} color={color} />
                        <IconButton className="modalClose" component="span" onClick={() => { this.setState({ open: false }) }}>
                            <Icon>close</Icon>
                        </IconButton>
                        <div className="people-card-body" style={{ maxHeight: height, overflowY: "auto" }}>
                            {component}
                        </div>
                    </Card>
                    
                </Dialog>
            </div>
        );
    }
}

export default Modal;
