import React, { Component } from "react";
import { Snackbar, Button } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class Http extends Component {

    constructor(props) {
        super(props);
        this.state = {
            snackbar: {
                show: false,
                variant: "error",
                message: ""
            },
            dialog: {
                show: false,
                message: ""
            }
        };
        Http.processFetch = Http.processFetch.bind(this);
        Http.processCheck = Http.processCheck.bind(this);
        Http.ShowSnackbar = Http.ShowSnackbar.bind(this);
        Http.ShowDialog = Http.ShowDialog.bind(this);
    }

    static Call = (method, endpoint, body = null) => new Promise((resolve, reject) => {
        const API_URL = process.env.REACT_APP_URL_API;
        fetch(API_URL + endpoint, {
            method: method,
            headers: {
                Authorization: JSON.parse(localStorage.getItem('loginToken')) ? "Bearer " + JSON.parse(localStorage.getItem('loginToken')).token : "",
                "Content-Type": "application/json",
            },
            body: body === null ? null : JSON.stringify(body),
        }).then(response => {
            return (Http.processFetch(response));
        }).then(responseJson => {
            resolve(Http.processCheck(responseJson));
        }).catch(error => {
            reject(error);
        });
    })

    static ShowSnackbar(variant, message) {
        this.setState({ snackbar: { show: true, variant: variant, message: message } });
    }

    static ShowDialog(message) {
        this.setState({ dialog: { show: true, message: message } });
    }

    static processFetch(response) {
        var result = { error: true };
        switch (response.status) {
            case 200:
                result = response.json();
                break;
            case 401:
                localStorage.removeItem('userSession');
                location.href = '/';
                break;
            default:
                break;
        }
        return result;
    }

    static processCheck(responseJson) {
        if (responseJson.error) {
            if (responseJson.publicMessage) {
                if (responseJson.fixedMessage) {
                    Http.ShowDialog(responseJson.message);
                } else {
                    Http.ShowSnackbar("error", responseJson.message);
                }
            } else {
                Http.ShowSnackbar("error", "Error en la conexión con el servidor");
            }
        } else {
            if (responseJson.publicMessage) {
                if (responseJson.fixedMessage) {
                    Http.ShowDialog(responseJson.message);
                } else {
                    Http.ShowSnackbar("success", responseJson.message);
                }
            }
        }
        return responseJson;
    }

    render() {
        const { snackbar, dialog } = this.state;
        return (
            <div>
            <Snackbar open={snackbar.show} autoHideDuration={2000} onClose={() => this.setState({ snackbar: { show: false, variant: snackbar.variant, message: snackbar.message }})}>
                <MuiAlert elevation={6} variant="filled" severity={ snackbar.variant }>
                    {snackbar.message}
                </MuiAlert>
            </Snackbar >
            <Dialog
                open={dialog.show}
                onClose={() => this.setState(prevState => ({ dialog: { ...prevState.dialog, show: false } }))}
              >
                <DialogTitle id="alert-dialog-title">Mensaje de PEOPLE</DialogTitle>
                <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialog.message}
                  </DialogContentText>
                </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState(prevState => ({ dialog: { ...prevState.dialog, show: false } }))} color="primary" autoFocus>
                                Aceptar
                  </Button>
                </DialogActions>
            </Dialog>
            </div>
        )
    }
}

export default Http;