import React from 'react';
import * as serviceWorker from './serviceWorker';
import historyRouting from "./history"; //historial de rutas
import ReactDOM from "react-dom";
import App from "./App"; //rutas
import { Router,Route } from "react-router-dom"; //enrutamiento
import registerServiceWorker from "./registerServiceWorker"; //produccion

ReactDOM.render(
    <Router history={historyRouting}>
      <Route component={App} />
    </Router>,
  document.getElementById('root')
);

registerServiceWorker();
serviceWorker.unregister();
