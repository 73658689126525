import React, { Component } from "react";
import { TextField, Button, Icon, Chip, Box, Grid } from '@material-ui/core';
import Http from "../../../global/Actions/http"
import Loading from '../../../global/Components/loading';
import Moment from 'react-moment';
import FileBase64 from 'react-file-base64';

class detailRequest extends Component {

    constructor(props) {
        const userSession = JSON.parse(localStorage.getItem('userSession'));
        super(props);
        this.state = {
            idRequest: this.props.idRequest,
            loading: true,
            upload: [],
            Request: null,
            approvalArea: false,
            idUser: userSession.idUser
        }
        this.handleUpdate = this.handleUpdate.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.getRequest = this.getRequest.bind(this);
    }

    componentDidMount() { // Eventos que se lanzarán al cargar el componente
        this.getRequest();
    }

    getRequest() {
        const userSession = JSON.parse(localStorage.getItem('userSession'));
        this.setState({ approvalArea: false });
        Http.Call("GET", "Requests/Details/"+this.state.idRequest).then(response => { // Captura de ausencias guardadas del usuario en curso
            if (!response.error) {
                this.setState({ Request: response.data, loading: false }); // Las trasladamos al state
                this.props.setReload ? this.props.setReload() : null;
                if (response.data.idUserManager === userSession.idUser || userSession.idRole == 4) {
                    this.setState({ approvalArea: true });
                }
            }
        });
    }

    async handleFiles(files) { // Handle para controlar la conversión de ficheros de input file a base64
        var sFiles = [];
        files.map(file => {
            var objfile = { "idRequest": this.state.idRequest, "fileName": file.name, "fileContent": file.base64.split("base64,")[1] } // Creamos objeto a insertar en array segun estructura POST de la API
            sFiles.push(objfile);
        })
        await this.setState({ upload: sFiles });
        this.uploadFiles();
    }

    uploadFiles() {
        var files = this.state.upload;
        files.map(file => {
            Http.Call("POST", "Requests/UploadFile/", { IdRequest: this.state.idRequest, FileName: file.fileName, FileContent: file.fileContent }).then(response => { //Realizo la llamada GET
                this.getRequest();
            })
        })
        this.setState({ upload: [] });
    }

    downloadFile(filename) {
        Http.Call("POST", "Requests/DownloadFile/", { IdRequest: this.state.idRequest, FileName: filename }).then(response => { //Realizo la llamada GET
            if (!response.error) {
                const linkSource = 'data:application/pdf;base64,' + response.data.fileContent;
                const downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                downloadLink.download = response.data.fileName;
                downloadLink.click();
            }
        })
    }

    async handleUpdate(e) { // Handle para controlar en state los valores de los elementos de nueva solicitud
        e.persist(); // Mantengo persistente el estado del elemento hasta el final del controlador
        var value = e.target.value; // Vuelco en la variable value el valor de elemento
        await this.setState(prevState => ({ Request: { ...prevState.Request, [e.target.name]: value } })); // Establezco en el state el valor del campo actual respetando el resto de elementos
    }

    async ManagerUpdate(IdRequestStatus) { // Mandamos la solicitud de nueva petición de ausencia a la API
        var approval = {
            IdRequestStatus : IdRequestStatus,
            requestManagerRemarks : this.state.Request.requestManagerRemarks
        };
        Http.Call("POST", "Requests/Approval/" + this.state.idRequest, approval).then(response => { //Realizo la llamada PUT
            if (!response.error) {
                this.getRequest();
            }
        })
    }

    render() {
        const { Request, loading, approvalArea } = this.state; // Creo constantes de objetos a partir del state del componente
        const idTenant = localStorage.getItem('idTenant');
        return (
                <div>
                { loading ? <Loading /> :
                    <><Grid container>
                        <Grid item xs={8}>
                            <h2>Categoría: {Request.requestTypeParentName}</h2>
                        </Grid>
                        <Grid item xs={4}>
                            <Chip label={Request.idRequestStatus == 1 ? "APROBADA" : Request.idRequestStatus == 2 ? "DENEGADA" : "PENDIENTE"} className={Request.idRequestStatus == 1 ? "gLight" : Request.idRequestStatus == 2 ? "rLight" : "yLight"} style={{ float: 'right' }} />
                        </Grid>
                    </Grid><hr />
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Box mt={2}>
                                    <strong>Tipo específico:</strong><br />
                                    {Request.requestTypeName}
                                </Box>
                                <Box mt={2}>
                                    <strong>Solicitud realizada por:</strong><br />
                                    {Request.userFullname}
                                </Box>
                                <Box mt={2}>
                                    <strong>Fecha y hora de solicitud:</strong><br />
                                    <Moment format="DD/MM/YYYY HH:mm" utc local>{Request.requestCreated}</Moment>
                                </Box>
                                <Box mt={2}>
                                    <strong>Observaciones del solicitante:</strong><br />
                                    {Request.requestRemarks}
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                {Request.requestAmount > 0 &&
                                    <Box mt={2}>
                                        <strong>Importe asociado a la petición:</strong><br />
                                        {Request.requestAmount}€
                                </Box>
                                }
                                {Request.idRequestTypeParent === 1 &&
                                <>
                                <Box mt={2}>
                                    <strong>Inicio de la ausencia:</strong><br />
                                        <Moment format="DD/MM/YYYY HH:mm">{Request.requestFrom}</Moment>
                                </Box>
                                <Box mt={2}>
                                    <strong>Fin de la ausencia:</strong><br />
                                        <Moment format="DD/MM/YYYY HH:mm">{Request.requestTo}</Moment>
                                </Box>
                                </>
                                }
                                <Box mt={2}>
                                    <strong>Responsable de autorización:</strong><br />
                                    {Request.userManagerName == "" ? "No disponible" : Request.userManagerName + " " + Request.userManagerSurname}
                                </Box>
                                <Box mt={2}>
                                    <strong>Fecha y hora de revisión:</strong><br />
                                    {Request.requestSolved == "0001-01-01T00:00:00" || Request.requestSolved == null ? <div>Pendiente de revision</div> : <Moment format="DD/MM/YYYY HH:mm" utc local>{Request.requestSolved}</Moment>}
                                </Box>
                                <Box mt={2}>
                                    <strong>Comentarios del responsable:</strong><br />
                                    {Request.requestManagerRemarks == null ? "" : Request.requestManagerRemarks }
                                </Box>
                            </Grid>
                            {Request.files.length > 0 &&
                                <Grid item xs={12}><br />
                                    <strong>Ficheros subidos:</strong>
                                    <Grid container>
                                    {Request.files.map(file => <Grid item xs={12}>
                                        <Button onClick={() => this.downloadFile(file.fileName)} component="label"><Icon>attach_file</Icon>{file.fileName}</Button>
                                        </Grid>
                                        )}
                                    </Grid>
                                </Grid>}
                            <Grid item xs={6}><br />
                                <Button variant="contained" color="default" startIcon={<Icon>cloud_upload</Icon>} component="label" className="upload" color="secondary">Subir documentación
                                    <FileBase64 onDone={this.handleFiles.bind(this)} />
                                </Button>
                            </Grid>
                            <Grid item xs={6}><br />
                                {idTenant < 3 && //TODO
                                    <Button href="" target="_blank" variant="contained" color="primary">Firmar Documento</Button>
                                }
                            </Grid>
                        
                            {approvalArea==true ?
                                <Grid container spacing={2} className="approvalArea">
                                    <Grid item xs={12}>
                                        <h2>{Request.idRequestStatus <= 2 ? "Actualizar aprobación" : "Gestionar aprobación"}</h2>
                                        {Request.idRequestStatus <= 2 ? <small>Esta solicitud fue gestionada el <Moment format="DD/MM/YYYY" utc local>{Request.requestSolved}</Moment>. Si actualizas la apróbación, el usuario que la realizó recibirá una notificación.</small> : null }
                                        <hr />
                                        
                                </Grid>
                                    <Grid item xs={8}>
                                        Observaciones sobre aprobación o rechazo.
                                        <TextField className="approvalTextField" id="requestManagerRemarks" name="requestManagerRemarks" fullWidth value={Request.requestManagerRemarks == null ? "" : Request.requestManagerRemarks} onChange={this.handleUpdate} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <br/>
                                        <Button fullWidth variant="contained" color="default" startIcon={<Icon>thumb_up</Icon>} component="label" className="rok" onClick={ () => this.ManagerUpdate(1) }>
                                        </Button>
                                        <Button fullWidth variant="contained" color="default" startIcon={<Icon>thumb_down</Icon>} component="label" className="rko" onClick={() => this.ManagerUpdate(2) }>
                                        </Button>
                                    </Grid>
                            </Grid>
                            : <div></div>
                        }  </Grid> </>
                    }
                </div>
        );
    }
}

export default detailRequest;
