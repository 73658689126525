import React, { Component } from "react";
import { TextField, Button, NativeSelect, Grid } from '@material-ui/core';
import Http from "../../../global/Actions/http"
import Loading from '../../../global/Components/loading';
import Moment from 'react-moment';
import DynamicTable from "../../../global/Components/dynamicTable";

class detailReport extends Component {

    constructor(props) {
        const userSession = JSON.parse(localStorage.getItem('userSession'));
        super(props);
        this.state = {
            idReport: this.props.idReport,
            users: [],
            loading: true,
            report: {
                reportTitle:''
            },
            idUser: userSession.idUser,
            areyousure: false
        }
        this.handleUpdate = this.handleUpdate.bind(this);
        this.getReport = this.getReport.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.updateReport = this.updateReport.bind(this);
        this.deleteReport = this.deleteReport.bind(this);
    }

    componentDidMount() { // Eventos que se lanzarán al cargar el componente
        this.getUsers();
        this.getReport();
    }

    getUsers() {
        Http.Call("GET", "Users/All").then(response => { // Captura de ausencias guardadas del usuario en curso
            if (!response.error) {
                this.setState({ users: response.data });
            }
        });
    }

    getReport() {
        if (this.state.idReport > 0) {
            Http.Call("GET", "Reports/Details/" + this.state.idReport).then(response => { // Captura de ausencias guardadas del usuario en curso
                if (!response.error) {
                    this.setState({ report: response.data, loading: false }); // Las trasladamos al state
                }
            });
        } else {
            this.setState({ Report: { ReportName: '' }, loading: false })
        }
    }

    updateReport() {
        if (this.state.idReport > 0) { //Crear
            Http.Call("PUT", "Reports/Update/" + this.state.idReport, this.state.report).then(response => { // Captura de ausencias guardadas del usuario en curso
                if (!response.error) {
                    this.getReport();
                    this.props.setReload ? this.props.setReload() : null;
                }
            });
        }
        else
        {
            Http.Call("POST", "Reports/New", this.state.report).then(response => { // Captura de ausencias guardadas del usuario en curso
                if (!response.error) {
                    this.setState({ idReport: response.data.idReport })
                    this.getReport();
                    this.props.setReload ? this.props.setReload() : null;
                }
            });
        }
    }

    deleteReport() {
        Http.Call("DELETE", "Reports/Delete/" + this.state.idReport).then(response => { // Captura de ausencias guardadas del usuario en curso
            if (!response.error) {
                this.setState({ idReport: 0, areyousure: false });
                this.getReport();
                this.props.setReload ? this.props.setReload() : null;
            }
        });
    }

    async handleUpdate(e) { // Handle para controlar en state los valores de los elementos de nueva solicitud
        e.persist(); // Mantengo persistente el estado del elemento hasta el final del controlador
        var value = e.target.value; // Vuelco en la variable value el valor de elemento
        await this.setState(prevState => ({ report: { ...prevState.report, [e.target.name]: value } })); // Establezco en el state el valor del campo actual respetando el resto de elementos
    }

    render() {
        const { report, loading, areyousure } = this.state; // Creo constantes de objetos a partir del state del componente
        return (
                <div>
                { loading ? <Loading /> :
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <strong>Titulo del informe:</strong><br />
                                <TextField id="reportTitle" name="reportTitle" value={report.reportTitle} onChange={this.handleUpdate} fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <strong>Código de inserción:</strong><br />
                                <TextField id="reportEmbedCode" name="reportEmbedCode" value={report.reportEmbedCode} onChange={this.handleUpdate} fullWidth />
                            </Grid>
                            
                            {report.idReport > 0 ?
                            <>
                            <Grid item xs={12}>
                                    <Button className="float-right" variant="outlined" onClick={() => { areyousure ? this.deleteReport() : this.setState({ areyousure: true }); }} style={{ marginLeft: '10px' }}>{!areyousure ? "Eliminar" : "¿Seguro?"}</Button>
                                    <Button className="float-right" variant="outlined" onClick={this.updateReport}>Actualizar</Button>     
                            </Grid>
                            </>
                                :
                            <Grid item xs={6}>
                                <br />
                                <Button variant="outlined" fullWidth onClick={this.updateReport}>Dar de alta</Button>
                            </Grid>
                            }
                        </Grid> </>
                    }
                </div>
        );
    }
}

export default detailReport;
