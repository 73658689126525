import React, { Component } from "react";
import { Chip, Grid } from '@material-ui/core';
import Http from "../../../global/Actions/http"

import Loading from '../../../global/Components/loading';
import Moment from 'react-moment';
import Modal from '../../../global/Components/modal';
import DetailRequest from "./detailRequest"

class ListRequests extends Component {

    constructor(props) {
        const userSession = JSON.parse(localStorage.getItem('userSession'));
        super(props);
        this.state = {
            idRequestTypeParent: this.props.idRequestTypeParent,
            myRequests: [],  // Ausencias guardadas
            idUser: this.props.idUser ? this.props.idUser : userSession.idUser,
            loading: true,
            modal: {
                open: false,
                title: "",
                icon: "",
                color: "",
                component: "",
                size: "",
                key: Math.random()
            }
        }
        this.requests = this.requests.bind(this);
    }

    requests() {
        Http.Call("POST", "Requests/List/", { IdRequestTypeParent: this.state.idRequestTypeParent, IdUser: this.state.idUser }).then(response => { // Captura de ausencias guardadas del usuario en curso
            if (!response.error) {
                this.setState({ myRequests: response.data, loading: false }); // Las trasladamos al state
            }
        });
    }

    componentDidMount() { // Eventos que se lanzarán al cargar el componente
        this.requests();
    }

    render() {
        const { idRequestTypeParent, myRequests, modal, loading } = this.state; // Creo constantes de objetos a partir del state del componente
        return (
                <div>
                { loading ? <Loading /> :
                    myRequests.length == 0 ? <center>No hay registros</center> :
                        myRequests.map(Request =>
                            <div key={"itemreq-" + Request.idRequest } className="people-list clickable" onClick={() => { this.setState({ modal: { open: true, title: "Ver detalles", icon: "manage_accounts", color: "blue", size: "sm", component: <DetailRequest updateParent={this.myRequests} idRequest={Request.idRequest} />, key: Math.random() } }) }}>
                            <Grid container>
                                {idRequestTypeParent == 1 &&
                                    <Grid item xs={8}><strong>Ausencia por {Request.requestTypeName}</strong><br />
                                    <strong>Desde </strong><Moment format="DD/MM/YYYY">{Request.requestFrom}</Moment><strong> hasta </strong><Moment format="DD/MM/YYYY">{Request.requestTo}</Moment><br />
                                    </Grid>
                                }
                                {idRequestTypeParent == 2 &&
                                    <Grid item xs={8}><strong>Solicitud de {Request.requestTypeName}</strong><br />
                                        <strong>Fecha: </strong><Moment format="DD/MM/YYYY">{Request.requestCreated}</Moment> <strong>Importe: </strong>{Request.requestAmount}€<br />
                                    </Grid>
                                }
                                {idRequestTypeParent == 3 &&
                                    <Grid item xs={8}><strong>Documento {Request.requestTypeName}</strong><br />
                                        <strong>Fecha de subida: </strong><Moment format="DD/MM/YYYY">{Request.requestCreated}</Moment><br />
                                    </Grid>
                                }
                                    <Grid item xs={4}><Chip label={Request.idRequestStatus == 1 ? "APROBADA" : Request.idRequestStatus == 2 ? "DENEGADA" : "PENDIENTE"} className={Request.idRequestStatus == 1 ? "gLight" : Request.idRequestStatus == 2 ? "rLight" : "yLight"} style={{ float: 'right' }} /></Grid>
                                </Grid>
                            
                    </div>
                )}
                <Modal key={modal.key} open={modal.open} title={modal.title} icon={modal.icon} color={modal.color} component={modal.component} size={modal.size} />
                </div>
        );
    }
}

export default ListRequests;
