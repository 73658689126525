import React, { Component } from "react";
import Http from "../../global/Actions/http"
import { Container, Grid, Card } from '@material-ui/core';
import { FileIcon, defaultStyles } from 'react-file-icon';
import CardHeader from '../../global/Components/cardHeader';

class MyCompany extends Component {

    constructor(props) {
        super(props);
        this.state = {
            docs: []
        }
        this.downloadFile = this.downloadFile.bind(this);
    }

    componentDidMount() {
        Http.Call("GET", "Company/Docs").then(response => {
            if (!response.error) {
                this.setState({ docs: response.data });
            }
            
        });
    }

    downloadFile(folder, filename) {
        Http.Call("GET", "Company/DownloadFile/"+folder+"/"+filename).then(response => { //Realizo la llamada GET
            if (!response.error) {
                const linkSource = 'data:application/pdf;base64,' + response.data.fileContent;
                const downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                downloadLink.download = response.data.fileName;
                downloadLink.click();
            }
        })
    }

    render() {
        const { docs } = this.state;

        return (
            <Container className="company" maxWidth="xl">
                <Http/>
                <Grid id="header" container spacing={5} padding={10}>
                    <Grid item xs={12}><h2>Mi empresa</h2></Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Card className="people-card">
                            <CardHeader title="Enlaces de interés" icon="link" color="green" />
                            <div className="people-card-body">
                                <div className="people-list clickable" onClick={() => { window.open('', '_blank') }}>
                                    <Grid container spacing={1} padding={3}>
                                        <Grid item className="fileicon" xs={2}>
                                            <FileIcon extension="link" />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <div style={{ marginTop: "5px" }}>
                                                <strong>Portal de formación</strong><br />
                                                <div></div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="people-list clickable" onClick={() => { window.open('', '_blank') }}>
                                    <Grid container spacing={1} padding={3}>
                                        <Grid item className="fileicon" xs={2}>
                                            <FileIcon extension="link" />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <div style={{ marginTop: "5px" }}>
                                                <strong>Portal beneficios del empleado</strong><br />
                                                <div></div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                            </Card>
                        </Grid>
                    {
                        docs.map((folder, f) => (
                            folder.folderName !== "Plantillas" &&
                                    <Grid key={"itemfdr-" + f} item xs={12} sm={6} lg={4}>
                                        <Card className="people-card">
                                            <CardHeader title={folder.folderName} icon="folder" color="orange" />
                                            <div className="people-card-body">
                                                {
                                                    folder.files.length == 0 ? <center>No hay documentos disponibles</center> :
                                                        folder.files.map((file, i) =>
                                                            <div key={"itemreq-" + i} className="people-list clickable" onClick={() => { this.downloadFile(folder.folderName, file.fileName) }}>
                                                                <Grid container spacing={1} padding={3}>
                                                                    <Grid item className="fileicon" xs={2}>
                                                                        <FileIcon extension={file.fileExt} {...defaultStyles[file.fileExt]} />
                                                                    </Grid>
                                                                    <Grid item xs={10}>
                                                                        <div style={{ marginTop: "5px" }}>
                                                                            <strong>{file.fileName}</strong><br />
                                                                            <div>Descargar</div>
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        )
                                                }
                                            </div>
                                        </Card>
                                    </Grid>
                            
                        ))
                    }

                </Grid>

            </Container>
        );
    }
}

export default MyCompany;
