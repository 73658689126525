import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Grid, Hidden } from "@material-ui/core";
import Moment from 'react-moment';
import 'moment/locale/es';

import { version as app_version } from '../../../package.json';
import Candidato from '../../assets/images/generic.png';
import Http from "../../global/Actions/http"
import PeopleLogo from '../../assets/images/logo_people.png';



class Header extends Component {

    constructor(props) {
        super(props);
        const userSession = JSON.parse(localStorage.getItem("userSession"));
        this.state = {
            userSession: userSession,
            weather: null,
            now: new Date(),
            environment: window.location.hostname.includes("pre") || window.location.hostname.includes("localhost") ? "¡Entorno de test!" : ""
        }
        this.logOut = this.logOut.bind(this);  
        this.loadClock = this.loadClock.bind(this);
    }

    componentDidMount() {

        Http.Call("GET", "Weather/CityWeather/3117735").then(response => {
            if (!response.error) {
                this.setState({ weather: response.data });
            }
        });

        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible') {
                Http.Call("GET", "Login/Ping");
                this.loadClock();
            }
        });

        this.loadClock();
    }

    loadClock() {
        if (window.headerClock != undefined && window.headerClock != 'undefined') {
            window.clearInterval(window.headerClock);
        }
        window.headerClock = setInterval(
            () => this.setState({ now: new Date() }),
            1000
        );
    }

    logOut() {
        clearInterval(window.headerClock);
        clearInterval(window.timeAction);
        localStorage.removeItem('userSession');
        localStorage.removeItem('loginToken');
        location.href = "/";
    }

    render() {
        const { environment, weather, now, userSession} = this.state;

        return (
            <div className="header">
                <Http/>
                <Grid container className="headerbar">
                    <Grid item xs={9} md={3} lg={2} className='header-logo'>
                        <Link to='/home'>
                            <img src={PeopleLogo}/>
                            <div className='header-appversion'>{app_version} <span style={{ color: "red" }}>{environment}</span></div>
                        </Link>
                    </Grid>
                    <Grid item xs={3} md={9} lg={10}>
                        <Grid container>
                                <Hidden smDown>
                                <Grid item sm={6} lg={4} className='header-text'>
                                    {now.getHours() < 13 ? "Buenos días" : "Buenas tardes"} {userSession.userName}
                                    <div className='header-date'><Moment format="dddd DD [de] MMM [de] yyyy HH:mm:ss">{now}</Moment></div>
                                </Grid>
                                </Hidden>
                            <Hidden mdDown>
                                <Grid item lg={3} className='header-weather'>
                                    {weather &&
                                    <Grid container>
                                        <Grid item xs={12} sm={4} lg={2} className='header-weather-ico'>
                                            <img src={ weather.weatherIcoUrl }/>
                                        </Grid>
                                        <Grid item xs={12} sm={8} lg={10}  className='header-text'>
                                            Clima en {weather.weatherCityName}
                                            <div className='header-date'>Temp. actual: {weather.weatherTemp} º</div>
                                        </Grid>
                                        </Grid>
                                    }
                                    </Grid>    
                                </Hidden>
                            <Grid item xs={12} sm={6} lg={5}>
                                <Grid container>
                                    <Grid item xs={2} className='header-button'>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Hidden smDown>
                                            <div className='header-userSession'>{userSession.userName} {userSession.userSurname}</div>
                                            <div className='header-rol'>{userSession.userOffice !== "" ? userSession.userOffice : userSession.IdRole == 1 ? "Empleado" : userSession.IdRole == 2 ? "Responsable Dpto." : userSession.IdRole == 3 ? "Equipo RRHH" : "Administrador" }</div>
                                            <div className='header-email'>{userSession.userEmail} - <span onClick={this.logOut}>Desconectar</span></div>
                                        </Hidden>
                                    </Grid>
                                    <Grid item xs={3}><img className='header-avatar' src={userSession.userImage === '' || userSession.userImage === null ? Candidato : `data:image/png;base64,${userSession.userImage}`} /></Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withRouter(Header);
