import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react'
import { Container, Grid, Card, Chip, Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@material-ui/core';
import CardHeader from '../../global/Components/cardHeader';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import moment from 'moment';
import Http from "../../global/Actions/http"


export default function WorkTime() {

    const userSession = JSON.parse(localStorage.getItem('userSession'));
    const events = [];
    const localizer = momentLocalizer(moment);    
    const DragAndDropCalendar = withDragAndDrop(Calendar);
    const [draggedEvent, setDraggedEvent] = useState()
    const [projects, setProjects] = useState([])
    const [myEvents, setMyEvents] = useState([]);
    const [globalKey, setGlobalKey] = useState(0);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [canEdit, setCanEdit] = useState(true);
    const [nonWorkingDays, setNonWorkingDays] = useState([]);
    const [dialogConfirm, setDialogConfirm] = useState(false);

    //Capturamos la lista de proyectos asignados al usuario
    useEffect(() => {
        Http.Call("GET", "UsersProjects/All/" + userSession.idUser).then(response => {
            if (!response.error) {
                setProjects(response.data);
            }
        });
        Http.Call("GET", "Calendars/getDatesByUser/" + userSession.idUser).then(response => {
            if (!response.error) {
                setNonWorkingDays(response.data);
            }
        });
    }, []);

    useEffect(() => { if (projects.length>0) { getData(currentDate) }}, [projects]);

    const getData = (selectedDate) =>  {
        setCurrentDate(selectedDate);
        var body = {
            IdUser: userSession.idUser,
            Month: selectedDate.getMonth()+1,
            Year: selectedDate.getFullYear()
        };
        Http.Call("POST", "Tasks/List", body).then(response => {
            if (!response.error) {
                var events = [];
                response.data.map(event => {
                    events.push({
                        id: event.idTask,
                        idProject: event.idProject,
                        title: projects.filter(project => project.idProject == event.idProject)[0].projectName,
                        start: new Date(event.taskDateTime),
                        end: new Date(event.taskDateTime),
                        isallDay: false,
                        hours: event.taskTime,
                        color: '#3174ad',
                        origColor: '#3174ad'
                    });
                })
                setCanEdit(response.data.filter(task => task.taskConfirmed == false).length>0 || response.data.length==0);
                setMyEvents(events);
                reloadCalendar();
            }
        });
    }

    const saveData = () => {
        var events = [];
        console.log(myEvents);
        myEvents.map(event => {
            events.push({
                idProject: event.idProject,
                idUser: userSession.idUser,
                taskDateTime: event.start.getFullYear() + '-' + ((event.start.getMonth() * 1) + 1) + '-' + event.start.getDate() + ' 00:00:00',
                taskTime: event.hours,
                taskConfirmed: false,
            });
        })
        console.log("ANTES DE GUARDAR:"+events);
        var body = {
            IdUser: userSession.idUser,
            Month: currentDate.getMonth() + 1,
            Year: currentDate.getFullYear(),
            Tasks: events
        };
        console.log(body);
        Http.Call("POST", "Tasks/Save", body).then(response => {
            if (!response.error) {
                getData(currentDate);
            }
        });
    }

    const confirmMonth = () => {
        var body = {
            IdUser: userSession.idUser,
            Month: currentDate.getMonth() + 1,
            Year: currentDate.getFullYear()
        };
        Http.Call("POST", "Tasks/CloseMonth", body).then(response => {
            if (!response.error) {
                getData(currentDate);
                setDialogConfirm(false);
            }
        });
    }

    const handleUserKeyPress = useCallback(event => {
        const { key, keyCode } = event;
        if (key === "Delete") {
            setSelectedEvent(selectedEvent => {
                if (selectedEvent != null) {
                    setMyEvents(myEvents => {
                        return myEvents.filter(ev => ev.id !== selectedEvent);
                    });
                    setSelectedEvent(null);
                    reloadCalendar();
                }
                return selectedEvent;
            });            
        }
    }, []);

    useEffect(() => {
        window.addEventListener("keydown", handleUserKeyPress);
        return () => {
            window.removeEventListener("keydown", handleUserKeyPress);
        };
    }, [handleUserKeyPress]);

    const reloadCalendar = () => {
        setGlobalKey(globalKey + 1);
    }

    // Al actualizar las horas de un evento
    const updateHours = (id,hourdiff) => {
        setMyEvents(myEvents => {
            return myEvents.map(event => {
                return event.id === id ? { ...event, hours: event.hours+hourdiff } : event
            })
        });
        reloadCalendar();
    }

    // Al seleccionar un evento
    const selectEvent = (e) => {
        if (canEdit) {
            setMyEvents(myEvents => {
                return myEvents.map(event => {
                    return event.id === e.id ? { ...event, color: '#2e4b64' } : { ...event, color: event.origColor }
                })
            });
            setSelectedEvent(e.id);
        }
    }

    // Evento al iniciar el arrastrar
    const handleDragStart = useCallback((event) => setDraggedEvent(event), [])

    const dragFromOutsideItem = useCallback(() => draggedEvent, [draggedEvent])

    const customOnDragOver = useCallback(
        (dragEvent) => {
            // check for undroppable is specific to this example
            // and not part of API. This just demonstrates that
            // onDragOver can optionally be passed to conditionally
            // allow draggable items to be dropped on cal, based on
            // whether event.preventDefault is called
            if (draggedEvent !== 'undroppable') {
                dragEvent.preventDefault()
            }
        },
        [draggedEvent]
    )

    const handleDisplayDragItemInCell = useCallback(
        () => setDisplayDragItemInCell((prev) => !prev),
        []
    )

    const onDropFromOutside = useCallback(
        ({ start, end, allDay: isAllDay }) => {
            if (draggedEvent === 'undroppable') {
                setDraggedEvent(null)
                return
            }
            const event = {
                title: draggedEvent.projectName,
                idProject: draggedEvent.idProject,
                start,
                end,
                isAllDay,
            }
            setDraggedEvent(null)
            newEvent(event)
        },
        [draggedEvent, projects, setDraggedEvent, setProjects, newEvent]
    )

    //Al solar un elemento dentro de un día del calendario
    const newEvent = useCallback(
        (event) => {
            setMyEvents((prev) => {
                return [...prev, { ...event, id: Math.round(new Date().getTime()), hours: 8 ,color: '#3174ad', origColor: '#3174ad' }]
            })
        },
        [setMyEvents]
    )

    const moveEvent = useCallback(
        ({ event, start, end, isAllDay: droppedOnAllDaySlot = false }) => {
            const { allDay } = event
            if (!allDay && droppedOnAllDaySlot) {
                event.allDay = true
            }
            setMyEvents((prev) => {
                const existing = prev.find((ev) => ev.id === event.id) ?? {}
                const filtered = prev.filter((ev) => ev.id !== event.id)
                return [...filtered, { ...existing, id: event.id, start, end, allDay }]
            })
        },
        [setMyEvents]
    )

    //Evento reescalar
    const resizeEvent = ({ event, start, end }) => {
        var events = myEvents.filter(ev => ev.id !== event.id);
        console.log("Antes del resize:",events);
        while (end.getTime() > start.getTime()) {
            var tmpend = new Date(start.getTime());
            var tmpfrom = new Date(start.getTime());
            tmpend.setDate(start.getDate() + 1);
            tmpfrom.setDate(start.getDate());
            events.push(
                {
                    title: event.title,
                    id: Math.random(),
                    idProject: event.idProject,
                    start: tmpfrom,
                    end: tmpend,
                    isAllDay: false,
                    hours: 8,
                    color: event.origColor,
                    origColor: event.origColor
                }
            );

            console.log("Fecha:" + start);
            start.setDate(start.getDate() + 1);
        }
        console.log("Después del resize:", events);
        setMyEvents(events);
        reloadCalendar();
    }

    const customEvent = ({ event }) => {
        return (
            canEdit?
            <span style={{padding: '3px 10px'}}>
                {event.title}
                <span style={{ float: 'right', marginRight: '10px' }}>
                    <span onClick={() => { updateHours(event.id, -1) }}> - </span>
                    <small style={{ color: "yellow" }}>[{event.hours} horas]</small>
                    <span onClick={() => { updateHours(event.id, 1) }}> + </span>
                    </span>
                </span> :
                <span style={{ padding: '3px 10px' }}>
                    {event.title}
                    <span style={{ float: 'right', marginRight: '10px' }}>                      
                        <small style={{ color: "yellow" }}>[{event.hours} horas]</small>
                    </span>
                </span>
        )
    }

    const ConfirmDialog = () => {
        return (
            <Dialog
                open={dialogConfirm}
                onClose={() => { setDialogConfirm(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"¿Desea cerrar el parte mensual?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Esto cerrará el parte de trabajo mensual, y anviará una copia a su responsable. ¿Está seguro?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setDialogConfirm(false) }}>Cancelar</Button>
                    <Button onClick={confirmMonth} autoFocus>
                        Continuar
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <Container className="req" maxWidth="xl">
            {/* ---------------- CABECERA -------------------- */}
            <Grid id="header" container spacing={5} padding={10}>
                <Grid item xs={12}><h2>Mi parte diario de trabajo</h2></Grid>
            </Grid>
            {/* ---------------- FIN CABECERA -------------------- */}
            <Grid container spacing={5} padding={10}>
                <Grid item xs={8}>
                    {projects.map((project, i) => (
                        <Chip label={project.projectName}
                            style={{ margin: '0px 10px 15px 0px', color: canEdit ? "darkblue" : "gray" }}
                            draggable={canEdit}
                            key={"prj-" + i}
                            color={canEdit ? "primary" : "gray"}
                        variant="outlined"
                        onDragStart={() => handleDragStart(project)} />
                ))}
                </Grid>
                <Grid item xs={4}>
                    <Button variant="contained" color="primary" className="float-right" onClick={saveData} disabled={!canEdit}>Guardar</Button>
                    <Button variant="contained" className="float-right" style={{ marginRight: '10px', marginBottom: '20px' }} disabled={!canEdit} onClick={() => setDialogConfirm(true) }>Cerrar mes</Button>
                </Grid>
            </Grid>
            <Grid item xs={12} id="up">
                <Card className="people-card">
                    <CardHeader title="Calendario de mi equipo" icon="event" color="darkblue" />
                    <div className="people-card-body">
                        <DragAndDropCalendar
                            key={globalKey}
                            defaultDate={currentDate}
                            defaultView={Views.MONTH}
                            events={myEvents}
                            localizer={localizer}
                            onSelectEvent={selectEvent}
                            onNavigate={(e) => { getData(e) }}
                            onDropFromOutside={onDropFromOutside}
                            onDragOver={customOnDragOver}
                            onEventDrop={moveEvent}
                            onEventResize={resizeEvent}
                            onSelectSlot={newEvent}
                            popup
                            draggableAccessor={ event => canEdit }
                            resizable={canEdit}
                            dayPropGetter={(date) => {
                                var ndate = date.getFullYear() + '-' + ((date.getMonth() * 1) + 1) + '-' + date.getDate();
                                var cdate = nonWorkingDays.filter(d => (new Date(d.calendarDate).getFullYear() + '-' + ((new Date(d.calendarDate).getMonth() * 1) + 1) + '-' + new Date(d.calendarDate).getDate()) === ndate);
                                return { style: { backgroundColor: cdate.length>0 ? '#f7f7f7': 'white' } }
                            }}
                            style={{ height: "65vh" }}
                            messages={{
                                agenda: 'Agenda',
                                allDay: 'Día completo',
                                month: 'Mes',
                                day: 'Día',
                                today: 'Hoy',
                                previous: 'Anterior',
                                next: 'Siguiente',
                                date: 'Fecha',
                                noEventsInRange: 'Sin eventos',
                                time: 'Hora',
                                tomorrow: 'Mañana',
                                week: 'Semana',
                                work_week: 'Días laborables',
                                yesterday: 'Ayer'
                            }}
                            views={{
                                month: true,
                            }}
                            components={{
                                event: customEvent,
                            }}
                        />
                        </div>
                    </Card>
            </Grid>
            <ConfirmDialog/>
            </Container>
    )
}
