import React, { useState } from "react";
import DynamicTable from "../../global/Components/dynamicTable";
import { Container, Button, Grid, Card, Icon, Tooltip } from '@material-ui/core';
import CardHeader from '../../global/Components/cardHeader';
import Modal from '../../global/Components/modal';
import DetailDepartment from "./Components/detailDepartment";

function Departments() {

    const [reload, setReload] = useState(0);
    const [key, setKey] = useState(Math.random());
    const [modal, setModal] = useState({
        open: false,
        title: "",
        icon: "",
        color: "",
        component: "",
        size: "",
        key: Math.random(),
        height: "65vh"
    });

    //Definición de columnas
    const columns = [
        { accessor: "idDepartment", Header: "Id", width: "10%" },
        { accessor: "departmentName", width: "30%", Header: "Nombre del departamento" },
        { accessor: "departmentManagerFullname", width: "30%", Header: "Responsable del departamento" },
        { accessor: "departmentTotalUsers", width: "20%", Header: "Número de miembros" },
        { accessor: "departmentOrder", width: "30%", Header: "Posición directorio" },
        {
            Header: "Acciones", filterable: false, width: "10%", Cell: props =>
                <div>
                    <Tooltip title="Ver detalles">
                        <Button variant="outlined" onClick={() => { setModal({ open: true, title: "Configuración de departamento", icon: "manage_accounts", color: "blue", size: "sm", component: <DetailDepartment setReload={() => setReload(Math.random())} idDepartment={props.row.original.idDepartment} />, key: Math.random() }) }}><Icon>more_horiz</Icon></Button>
                    </Tooltip>
                </div>
        }
    ];


    return (
        <Container className="departments req" maxWidth="xl">
            {/* ---------------- CABECERA -------------------- */}
            <Grid id="header" container spacing={5} padding={10}>
                <Grid item xs={12}><h2>Departamentos</h2></Grid>
            </Grid>
            {/* ---------------- FIN CABECERA -------------------- */}
            <Button variant="outlined" className="elementInDynamicTableHeader"
                onClick={() => { setModal({ open: true, title: "Configuración de departamento", icon: "manage_accounts", color: "blue", size: "sm", component: <DetailDepartment setReload={() => setReload(Math.random())} idDepartment="0" />, key: Math.random() }) }}
            >Nuevo departamento</Button>
            <Card className="people-card dynamicTableHeaderAllowElements">
                <CardHeader title="Configuración de departamentos" icon="history" color="red" />
                <DynamicTable reload={reload} key={key} isOrdered={true} isGlobalSearch={true} columns={columns} endpoint={"Departments/All"} />
                <Modal key={modal.key} height={modal.height} open={modal.open} title={modal.title} icon={modal.icon} color={modal.color} component={modal.component} size={modal.size} />
            </Card>
        </Container>
    )
}

export default Departments;