import React, { useGlobal, Component } from "react";
import { Grid } from "@material-ui/core";
import { Route, Redirect } from "react-router-dom";

import Menu from './menu'
import Header from './header'


const PrivateRoute = ({ component: Component, ...props }) => {
    return (
        <Route
            {...props}
            render={
                props => JSON.parse(localStorage.getItem('userSession')) === null
                    ? <Redirect to="/" />
                    : (
                        <Grid container>
                            <Grid item xs={12} >
                                <Header />
                            </Grid>
                            <Grid item xs={12} md={3} lg={2} className="menubar">
                                <Menu />
                            </Grid>
                            <Grid item xs={12} md={9} lg={10} className="outlet">
                                <Component {...props} />
                            </Grid>
                        </Grid>
                    )
            }
        />
    )
};
export default PrivateRoute;
